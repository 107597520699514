import React, {useEffect, useState} from "react";
import Renderif from "../../../utils/renderif";
import {ClinicShiftRequestPreferences} from "./shift_request_preferences/clinic_shift_request_preferences";
import {HospitalShiftRequestPreferences} from "./shift_request_preferences/hospital_shift_request_preferences";
import {Service} from "../../../services/api";
import {IoIosArrowBack} from "react-icons/io";
import {darkBlue} from "../../../styles/colors";
import {FaClinicMedical} from "react-icons/fa";

const isHospital = (centerTypeId) => {
  return centerTypeId === 2;
}

export const ShiftRequestPreferences = ({
  organizationId,
  centerId,
  centerTypeId,
  centerName,
  professionalField,
  assistanceUnit,
  onBackButton
}) => {

  const [state, setState] = useState({
    organization_preferences:[],
    center_preferences:[]
  })

  useEffect(() => {
    const initData = async () => {
      await loadPreferences(organizationId);
    }

    initData();
  }, []);

  const backButton = () => {
    onBackButton();
  };

  const loadPreferences = async (organizationId) => {
    let _organization_preferences = await Service('GET', `organization_preferences/${organizationId}`)
    state.organization_preferences = _organization_preferences.data.list;
    state.center_preferences = _organization_preferences.data.list.filter((pref) => pref.idCenter === centerId)
    setState({...state})
  }

  const onAddPreference = async ({organizationId, centerId, idAssistanceUnit, idProfessionalField, reloadPreferences}) => {
    await Service('POST', 'organization_preferences/create', {
      idOrganization: organizationId,
      idAssistanceUnit: idAssistanceUnit,
      idProfessionalField: idProfessionalField,
      idCenter: centerId
    });
    reloadPreferences(organizationId);
  }

  const onDeletePreference = async ({centerPreferences, organizationId, idAssistanceUnit, idProfessionalField, reloadPreferences}) => {
    let _pref = centerPreferences.find((pref)=> pref.idAssistanceUnit === idAssistanceUnit && pref.idProfessionalField === idProfessionalField)
    await Service('DELETE', `organization_preferences/delete/${_pref.id}`);
    reloadPreferences(organizationId)
  }

  return (
    <div className='container-fluid container-xl mx-auto p-3'>
      <div className='d-flex align-items-center'>
        <div className='d-flex cursor-pointer' onClick={backButton}>
          <div className='nu-icon nu-icon--mid'>
            <IoIosArrowBack size={20} color={darkBlue}></IoIosArrowBack>
          </div>
          <div className='f-16 bold text-darkblue ms-2'>Atrás</div>
        </div>

        <div className='col'></div>

        <div className='nu-icon nu-icon--mid'>
          <FaClinicMedical size={16} color={darkBlue}></FaClinicMedical>
        </div>
        <div className='f-16 bold text-darkblue ms-2'>{centerName}</div>
      </div>


      <Renderif isTrue={!isHospital(centerTypeId)}>
        <ClinicShiftRequestPreferences
          organizationId={organizationId}
          centerId={centerId}
          professionalField={professionalField}
          centerPreferences={state.center_preferences}
          onAddPreference={onAddPreference}
          onDeletePreference={onDeletePreference}
          reloadPreferences={loadPreferences}
        ></ClinicShiftRequestPreferences>
      </Renderif>

      <Renderif isTrue={isHospital(centerTypeId)}>
        <HospitalShiftRequestPreferences
          organizationId={organizationId}
          centerId={centerId}
          centerTypeId={centerTypeId}
          centerPreferences={state.center_preferences}
          assistanceUnit={assistanceUnit}
          professionalField={professionalField}
          onAddPreference={onAddPreference}
          onDeletePreference={onDeletePreference}
          reloadPreferences={loadPreferences}
        ></HospitalShiftRequestPreferences>
      </Renderif>

      <div className='my-5 py-5'></div>

    </div>
  );
};