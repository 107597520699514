import React, { useContext, useState } from 'react'
import { IoMdClose, IoMdStar } from "react-icons/io";
import { NuCalendarClinic } from '../../components/nu_calendar';
import { AdminSideMenu } from '../../components/nu_sidemenu';
import {NuTurnoItemAdmin} from '../../components/nu_turno_item';
import NuSelect from '../../components/nu_select';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/auth_context';
import { NotificationContext } from '../../context/notification_context';
import {fetchShifts, Service} from '../../services/api';
import { useEffect } from 'react';
import { AdminContext } from '../../context/admin_context';
import NuLoading from '../../components/nu_loading';
import Renderif from '../../utils/renderif';
import { BsThreeDotsVertical } from 'react-icons/bs';
import {ShiftCalendar} from "../../components/shift/shift_calendar";
import {getStartOfCurrentDay, getStartOfNextDay} from "../../utils/dates";

export default function TurnosAdminView() {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const url_shift = urlParams.get('shift')

    const navigate = useNavigate();
    const {assistanceUnit,professionalField, center} = useContext(AdminContext)

    const {user_data, user} = useContext(AuthContext)
    const {notify} = useContext(NotificationContext)


    const [state, setState] = useState({
        shiw_menu:false,
        show_turno_detail:false,
        shiw_filters:false,
        daySelected: null,
        turnos:[],
        date_filter:{
            init:'',
            end:''
        },
        filters:{
            center:'',
            profesion:'',
            horario:'',
            status:'',
            unidad:'',
            campo:'',
            valoracion:''
        },
        url_shift:url_shift
    })

    useEffect(()=>{
        if(state.daySelected !== null){
            state.url_shift = null
            setState({...state})
        }

    },[state.daySelected])

    useEffect(()=>{

        let _save_filters = JSON.parse(localStorage.getItem('filters'))
        if(_save_filters !== null){
            state.filters = _save_filters
        }

        init();

        return () => {}
    },[])

    const save_filters = () => {
        localStorage.setItem('filters', JSON.stringify(state.filters))
    }

    const init = async () => {
        const shifts = await fetchShifts({startDate: getStartOfCurrentDay()});
        setState({
            ...state,
            turnos: shifts
        })
    };

    const getDateFilterFromState = () => {
        let startDate = getStartOfCurrentDay();
        let endDate = null;
        if (state.daySelected) {
            startDate = state.daySelected;
            endDate = getStartOfNextDay(state.daySelected)
        } else if (state.date_filter.init || state.date_filter.end) {
            if (state.date_filter.init) {
                startDate = new Date(state.date_filter.init)
            }

            if (state.date_filter.end) {
                endDate = getStartOfNextDay(new Date(state.date_filter.end))
            }
        }

        return {
            startDate: startDate,
            endDate: endDate
        }
    }

    const checkCompleted = (item) => {
    
        let _today = new Date();
        var end_turno = parseInt(item?.initialHour.split(':')[0])
        var today = new Date(Date.UTC(_today.getFullYear(), _today.getMonth(), _today.getDate(), 0,0,0)).getTime()
        let _date = new Date(item?.date).getTime()

        if(today > _date){
            return true
        }
        if(today < _date){
            return false;
        }
        if(today === _date){
            if(_today.getHours() > end_turno){
                return true
            }
            return false;
        }
    }

    const filter = () => {
        if(state.url_shift){
            let _turnos = state.turnos.filter((turno) => turno.id === parseInt(state.url_shift))
            return _turnos;
        }

        let _turnos = state.turnos.sort(function(a,b){return a.date - b.date});
        return filters(_turnos);
    }

    const filters = (_turnos) => {
        for (const [key, value] of Object.entries(state.filters)) {
        
            if(value !== ''){
                
               if(key === 'center'){
             
                _turnos =  _turnos.filter((item) => item.idCenter === parseInt(value))
               }
               if(key === 'profesion'){
                _turnos =  _turnos.filter((item) => item.profession === value)
               }
               if(key === 'horario'){
                _turnos =  _turnos.filter((item) => item.timeOfDay === value)
               }
               if(key === 'status'){
                _turnos =  _turnos.filter((item) => {


                    switch (value) {
                        case '0':
                            if(item.status === "0" && !checkCompleted(item)){
                                return true
                            }
                            return false

                        case '1':

                            if(item.status === "1" && !checkCompleted(item)){
                                return true
                            }
                            return false
                        

                        case '2':

                            if(checkCompleted(item) && item.idUser !== null && item?.status !== '4'){
                                return true
                            }
                            return false
                        
                        case '3':

                            if(item.status === "4"){
                                return true
                            }
                            return false
                        
                        case '4':

                            if(item.status === "3"){
                                return true
                            }
                            return false
                        
                        case '5':

                            if(checkCompleted(item) && item.idUser === null){
                                return true
                            }
                            return false
                        
                        default:
                            return true;
                    }

                   
                })
               }
               if(key === 'unidad'){
                _turnos =  _turnos.filter((item) => item.idAssistanceUnit === parseInt(value))
               }
               if(key === 'campo'){
                _turnos =  _turnos.filter((item) => item.idProfessionalField === parseInt(value))
               }
               if(key === 'valoracion'){
              
                if(value === "1"){
                    _turnos =  _turnos.filter((item) => item.rating?.length === 0)
                }
                if(value === "2"){
                    _turnos =  _turnos.filter((item) => item.rating?.length > 0)
                }
               }
            }
        }
        return _turnos;
    }


    const onDeleteTurno = (item) => {

        notify({title:'Eliminar turno', message:'¿Deseas eliminar este turno?', type:'confirmation', onAccept: async (value) => {
            if(value){
                setState({...state, loading:true})
                let _delete = await Service('DELETE', `shift/delete/${item.id}`);
          
                if(_delete.status === 200){
                    state.turnos = state.turnos.filter((turno) => turno.id !== item.id);
                }else{
                    notify({title:'Eliminar turno', message:_delete?.response?.data?.error, type:'error'})
                }
                setState({...state, loading:false})
            }
        }})
        
     
    }
    const onUnassign = (item) => {

        notify({title:'Deasignar turno', message:'¿Deseas liberar este turno?', type:'confirmation', onAccept: async (value) => {
            if(value){
                setState({...state, loading:true})
                let _unassign = await Service('PUT', `shift/free/${item.id}`);
                const dateFilter = getDateFilterFromState();
                state.turnos = await fetchShifts({
                    startDate: dateFilter.startDate,
                    endDate: dateFilter.endDate
                });
                setState({...state})
            }
        }})
        
     
    }

    const onDuplicateTurno = async (turno) => {

        setState({...state, loading:true})
  
        let _duplicate  = await Service('POST', `shift/duplicate/${turno.id}`)
    
        if(_duplicate.status === 201){
            notify({title:'¡Listo!', message:'Turno duplicado.', type:'success'})
        }else{
            notify({title:'Error!', message:_duplicate.response.data.error, type:'error'})
        }
        const dateFilter = getDateFilterFromState();
        state.turnos = await fetchShifts({
            startDate: dateFilter.startDate,
            endDate: dateFilter.endDate
        });
        setState({...state})
    };

    const checkAttendedRequest = (idAssistanceUnit, centerId, idOrganization, publicateTo, idUser) => {
        const isChecked = state.turnos.filter(
          (item) =>
            item.type === '0' &&
            item.idAssistanceUnit === idAssistanceUnit &&
            // item.center.id === centerId &&
            item.center.idOrganization === idOrganization &&
            (item.publicateTo === publicateTo || item.idUser === idUser || JSON.parse(item.publicateTo)?.includes(idUser))
        )
        return isChecked.length ? true : false
    }

  return (
    <>
        <Renderif isTrue={state.loading}>
            <NuLoading></NuLoading>
        </Renderif>
        <div className='container-fluid overflow-hidden d-flex h-100'>


           
            <AdminSideMenu onClose={()=>{setState({...state, show_menu:false})}} show={state.show_menu} page={'turnos'}/>
    


            <div className='container-fluid overflow-auto hide-scroll col  mx-auto'>

                <div className='bg-white sticky-top pt-1 pt-md-3 px-1 px-md-3'>
        

                    <div className='nu-snack'>
                    <div>
                            <div className='f-22 text-white bold'>Hola, Admin</div>
                            {/* <div className='d-flex align-items-center'>
                                <div className='nu-icon nu-icon--mid'>
                                    <IoMdStar color='#FFB054' size={24}></IoMdStar>
                                </div>

                                <div className='f-16 text-white'>5.0 (0 reseñas)</div>

                            </div> */}
                        
                    </div>
                    <div className='col'></div>
                        <div className='avatar avatar--mid' style={{backgroundImage: 'url(/assets/avatar.png)'}}></div>
                        <div className='nu-snack-overlay'></div>
                        <div className='ms-2 d-block d-sm-none'>
                            <div className='nu-icon nu-icon--mid cursor-pointer' onClick={()=>{
                                setState({...state, show_menu:true})
                            }}>
                                <BsThreeDotsVertical size={50} color='white'></BsThreeDotsVertical>
                            </div>
                        </div>
                    </div>
                        
                </div>


                <div className='container-fluid container-xl mx-auto d-none d-lg-flex flex-wrap px-3 mt-3 bg-white '>

                    <div className='col-12 col-md-3  d-flex align-items-end'>
            
                            <div className='f-22 bold'>Turnos publicados</div>
                    
                    </div>

                    <div className='col-12 col-md-9'>
                    
                            <div className='d-flex align-items-center'>

                                <div className='p-3 bg-gray-00 r-10 me-3'>
                                    <div className='f-14 bold'> Asignados: <span className='text-darkgeen bolder'>{filter().filter((item)=>  item.status === "1" ).length}</span></div>
                                </div>

                                <div className='p-3 bg-gray-00 r-10 mx-3'>
                                    <div className='f-14 bold'>Pendientes de asignar: <span className='text-orange bolder'>{filter().filter((item)=>  item.idUser === null ).length}</span></div>
                                </div>

                                {/* <div className='p-3 bg-gray-00 r-10 ms-3'>
                                    <div className='f-14 bold'>No asignados: <span className='text-red bolder'>100</span></div>
                                </div> */}

                                <div className='col'></div>
                                <div className='nu_btn nu_btn--overlay' onClick={()=>{
                                    navigate('/admin/publicar')
                                }}>
                                    <div className='nu_btn-text f-18'>Publicar nuevo turno</div>
                                    <div className='nu_btn-overlay'></div>
                                </div>
                            </div>
                    
                    </div>

                    
                </div>

                <div className='px-1 mt-2 d-block d-md-none'>
                   
                    <div className='d-flex align-items-center mt-2'>
                        <div className='col p-3 bg-gray-00 r-10'>
                            <div className='f-14 bold'> Asignados: <span className='text-darkgeen bolder'>{state.turnos.filter((item) => item.idUser !== null).length}</span></div>
                        </div>
                        <div className='mx-1'></div>
                        <div className='col p-3 bg-gray-00 r-10'>
                            <div className='f-14 bold'>Pendientes de asignar: <span className='text-orange bolder'>{state.turnos.filter((item) => item.idUser === null && ! checkCompleted(item)).length}</span></div>
                        </div>
                    </div>

                    <div className='d-flex mt-3'>
                        <div className='nu_btn nu_btn--overlay' onClick={()=>{
                            setState({...state, show_filters:!state.show_filters})
                        }}>
                            <div className='nu_btn-text f-18'>Filtrar por</div>
                            <div className='nu_btn-overlay'></div>
                        </div>
                        <div className='col'></div>
                        <div className='nu_btn nu_btn--overlay' onClick={()=>{
                            navigate('/admin/publicar')
                        }}>
                            <div className='nu_btn-text f-18'>Publicar nuevo turno</div>
                            <div className='nu_btn-overlay'></div>
                        </div>
                    </div>
                    
                </div>

                <div className='container-fluid container-xl mx-auto d-flex flex-wrap px-2 px-md-3 bg-white mt-md-3'>
                    <div className='col-12 col-md-3 bg-sm-gray my-3 my-md-0 r-sm-10 px-3 px-md-0 d-none d-md-block'>
                        {/* sticky-top-header */}
                        <div className='py-1'>
                            <ShiftCalendar
                              disableDots={!!state.url_shift}
                              onChange={async (value)=>{
                                  state.date_filter.init = '';
                                  state.date_filter.end = '';
                                  if(value === null) {
                                      state.daySelected = null;
                                  } else {
                                      state.daySelected = new Date(value);
                                  }

                                  const dateFilter = getDateFilterFromState();
                                  state.turnos = await fetchShifts({
                                      startDate:dateFilter.startDate,
                                      endDate: dateFilter.endDate
                                  })
                                  setState({...state})
                              }}>
                            </ShiftCalendar>

                            <div className='mt-3'>
                                <div className='f-20 bold'>Filtrar por</div>
                                <NuSelect className={`mt-2 ${state.filters.center !== '' ? 'nu-select--active':''}`} value={state.filters.center} onChange={(evt)=>{
                                    state.filters.center = evt.currentTarget.value;
                                    save_filters()
                                    setState({...state})
                                }}>
                                    <option className='bg-light text-bg-danger' value="">Centro</option>
                                {
                                    center.map((item,i)=>{
                                        return (
                                            <option key={i} value={item.id}>{item.name}</option> 
                                        )
                                    })
                                }
                                </NuSelect>

                                <NuSelect className={`mt-2 ${state.filters.profesion !== '' ? 'nu-select--active':''}`} value={state.filters.profesion} onChange={(evt)=>{
                                    state.filters.profesion = evt.currentTarget.value;
                                    save_filters()
                                    setState({...state})
                                }}>
                                    <option value="">Profesión</option>
                                    <option value="1">TCAE</option>
                                    <option value="0">Enfermera</option>
                                </NuSelect>

                                <NuSelect className={`mt-2 ${state.filters.horario !== '' ? 'nu-select--active':''}`} value={state.filters.horario} onChange={(evt)=>{
                                    state.filters.horario = evt.currentTarget.value;
                                    save_filters()
                                    setState({...state})
                                }}>
                                    <option value="">Horario del turno</option>
                                    <option value="0">Mañana</option>
                                    <option value="2">Tarde</option>
                                    <option value="3">Noche</option>
                                    <option value="1">Día 12h</option>
                                    <option value="4">Guardia 24h</option>
                                </NuSelect>

                                <NuSelect className={`mt-2 ${state.filters.status !== '' ? 'nu-select--active':''}`} value={state.filters.status} onChange={(evt)=>{
                                    state.filters.status = evt.currentTarget.value;
                                    save_filters()
                                    setState({...state})
                                }}>
                                    <option value="">Estado del turno</option>
                                    <option value="0">Sin asignar</option>
                                    <option value="1">Asignado</option>
                                    <option value="2">Completado</option>
                                    <option value="3">No presentados</option>
                                    <option value="4">Solicitados</option>
                                    <option value="5">Expirados</option>
                                </NuSelect>

                                <NuSelect className={`mt-2 ${state.filters.unidad !== '' ? 'nu-select--active':''}`} value={state.filters.unidad} onChange={(evt)=>{
                                    state.filters.unidad = evt.currentTarget.value;
                                    save_filters()
                                    setState({...state})
                                }}>
                                    <option value="">Unidad Asistencial</option>
                                    {
                                        assistanceUnit.map((item, i)=>{
                                            return(
                                                <option key={i} value={item.id}>{item.description}</option> 
                                            )
                                        })
                                    }
                                </NuSelect>

                                <NuSelect className={`mt-2 ${state.filters.campo !== '' ? 'nu-select--active':''}`} value={state.filters.campo} onChange={(evt)=>{
                                    state.filters.campo = evt.currentTarget.value;
                                    save_filters()
                                    setState({...state})
                                }}>
                                    <option value="">Ambito profesional</option>
                                    {
                                        professionalField.map((item, i)=>{
                                            return(
                                                <option key={i} value={item.id}>{item.description}</option> 
                                            )
                                        })
                                    }
                                </NuSelect>

                                <NuSelect className={`mt-2 ${state.filters.valoracion !== '' ? 'nu-select--active':''}`} value={state.filters.valoracion} onChange={(evt)=>{
                                    state.filters.valoracion = evt.currentTarget.value;
                                    save_filters()
                                    setState({...state})
                                }}>
                                    <option value="">Valoración</option>
                                    <option value="1">Valoración pendiente</option>
                                    <option value="2">Valoración completada</option>
                                 
                                </NuSelect>

                            </div>
                        </div>

                    </div>
                    <Renderif isTrue={state.show_filters}>
                        <div className='col-12 col-md-3 bg-sm-gray my-3 my-md-0 r-sm-10 px-3 px-md-0 d-block d-md-none'>
                            {/* sticky-top-header */}
                            <div className='py-3'>
                                <ShiftCalendar
                                  disableDots={!!state.url_shift}
                                  onChange={async (value)=>{
                                      state.date_filter.init = '';
                                      state.date_filter.end = '';
                                      if(value === null) {
                                          state.daySelected = null;
                                      } else {
                                          state.daySelected = new Date(value);
                                      }

                                      const dateFilter = getDateFilterFromState();
                                      state.turnos = await fetchShifts({
                                          startDate:dateFilter.startDate,
                                          endDate: dateFilter.endDate
                                      })
                                      setState({...state})
                                  }}>
                                </ShiftCalendar>

                                <div className='mt-3'>
                                    <div className='f-22 bold'>Filtrar por</div>
                                    <NuSelect className={'mt-3'} value={state.filters.center} onChange={(evt)=>{
                                        state.filters.center = evt.currentTarget.value;
                                        setState({...state})
                                    }}>
                                        <option value="">Centro</option>
                                    {
                                        center.map((item,i)=>{
                                            return (
                                                <option key={i} value={item.id}>{item.name}</option> 
                                            )
                                        })
                                    }
                                    </NuSelect>

                                    <NuSelect className={'mt-2'} value={state.filters.profesion} onChange={(evt)=>{
                                        state.filters.profesion = evt.currentTarget.value;
                                        save_filters()
                                        setState({...state})
                                    }}>
                                        <option value="">Profesión</option>
                                        <option value="1">TCAE</option>
                                        <option value="0">Enfermera</option>
                                    </NuSelect>

                                    <NuSelect className={'mt-2'} value={state.filters.horario} onChange={(evt)=>{
                                        state.filters.horario = evt.currentTarget.value;
                                        save_filters()
                                        setState({...state})
                                    }}>
                                        <option value="">Horario del turno</option>
                                        <option value="0">Mañana</option>
                                        <option value="2">Tarde</option>
                                        <option value="3">Noche</option>
                                        <option value="1">Día 12h</option>
                                        <option value="4">Guardia 24h</option>
                                    </NuSelect>

                                    <NuSelect className={'mt-2'} value={state.filters.status} onChange={(evt)=>{
                                        state.filters.status = evt.currentTarget.value;
                                        save_filters()
                                        setState({...state})
                                    }}>
                                        <option value="">Estado del turno</option>
                                        <option value="0">Sin asignar</option>
                                        <option value="1">Asignado</option>
                                        <option value="2">Completado</option>
                                    </NuSelect>

                                    <NuSelect className={'mt-2'} value={state.filters.unidad} onChange={(evt)=>{
                                        state.filters.unidad = evt.currentTarget.value;
                                        save_filters()
                                        setState({...state})
                                    }}>
                                        <option value="">Unidad Asistencial</option>
                                        {
                                            assistanceUnit.map((item, i)=>{
                                                return(
                                                    <option key={i} value={item.id}>{item.description}</option> 
                                                )
                                            })
                                        }
                                    </NuSelect>

                                    <NuSelect className={'mt-2'} value={state.filters.campo} onChange={(evt)=>{
                                        state.filters.campo = evt.currentTarget.value;
                                        save_filters()
                                        setState({...state})
                                    }}>
                                        <option value="">Ambito profesional</option>
                                        {
                                            professionalField.map((item, i)=>{
                                                return(
                                                    <option key={i} value={item.id}>{item.description}</option> 
                                                )
                                            })
                                        }
                                    </NuSelect>

                                    <NuSelect className={'mt-2'} value={state.filters.valoracion} onChange={(evt)=>{
                                        state.filters.valoracion = evt.currentTarget.value;
                                        save_filters()
                                        setState({...state})
                                    }}>
                                        <option value="">Valoración</option>
                                        <option value="1">Valoración pendiente</option>
                                        <option value="2">Valoración completada</option>
                                        
                                    </NuSelect>

                                </div>
                            </div>

                        </div>
                    </Renderif>

                    <div className='col-12 col-md-9'>

                        <div className='ps-md-3 mt-3 mt-md-0'>  

                            <div className='d-none d-md-flex align-items-center'>
                                <div className='f-16 bold'>Filtrar por fecha de</div>
                                <div className='mx-2'>
                                    <input
                                        type='date'
                                        value={state.date_filter.init}
                                        onChange={async (evt)=>{
                                            state.date_filter.init = evt.currentTarget.value;
                                            const dateFilter = getDateFilterFromState();
                                            state.turnos = await fetchShifts({
                                                startDate: dateFilter.startDate,
                                                endDate: dateFilter.endDate
                                            });
                                            setState({...state})
                                        }}
                                        className='nu-input'></input>
                                </div>
                                <div className='f-16 bold'>a</div>
                                <div className='mx-2'>
                                    <input
                                        type='date'
                                        value={state.date_filter.end}
                                        onChange={async (evt)=>{
                                            state.date_filter.end = evt.currentTarget.value;
                                            const dateFilter = getDateFilterFromState();
                                            state.turnos = await fetchShifts({
                                                startDate: dateFilter.startDate,
                                                endDate: dateFilter.endDate
                                            });

                                            setState({...state})
                                        }}
                                        className='nu-input'></input>
                                </div>

                            </div>
                                

                            {
                                filter().filter((item)=> {return true }).map((item,i)=>{
                                    return (
                                        <NuTurnoItemAdmin 
                                        key={i} 
                                        item={item} 
                                        onDuplicate={(turno)=>{
                                            onDuplicateTurno(turno)
                                        }}
                                        onClick={()=>{
                                            setState({...state, show_turno_detail:true})
                                        }} 
                                        onUnassign={() =>{
                                            onUnassign(item)
                                        }}
                                        onDelete={()=>{
                                            onDeleteTurno(item)
                                        }}
                                        checkAttendedRequest={checkAttendedRequest}
                                        
                                        
                                        />
                                    )
                                })
                            }

                            <div className='my-5 py-5'></div>
                            <div className='my-5 py-5'></div>

                        </div>

                    
                    </div>

                </div>
        
            </div>

    
            <div className={`nu-turno-detail hide-scroll ${state.show_turno_detail ? 'nu-turno-detail--active':''}`} onClick={()=>{
                setState({...state, show_turno_detail:false })
            }}>
                <div className='nu-turno-content hide-scroll h-100' onClick={(evt)=>{evt.stopPropagation()}}>
                    DONDE COñO ESTA ESTO?????

                    <div className='d-flex'>
                        <div>
                            <div className='f-22 bold'>
                                CUAP Sagrera
                            </div>
                            <div className='d-flex align-items-center'>
                                <div className='nu-icon nu-icon--mid'>
                                    <IoMdStar color='#FFB054' size={24}></IoMdStar>
                                </div>
                                <div className='f-14 bold'>4.7 (70 Valoraciones)</div>
                            </div>
                        </div>
                        <div className='col'></div>
                        
                        <div className='nu-icon nu-icon--mid r-100 bg-darkblue m-1 cursor-pointer' onClick={()=>{
                            setState({...state, show_turno_detail:false})
                        }}>
                            <IoMdClose color='white' size={16}></IoMdClose>
                        </div>
                    </div>

                    <div className='mt-3'>
                        <div className='nu-turno-detail-image' style={{backgroundImage:'url(https://picsum.photos/300/120)'}}></div>
                        {/* <img  width="100%" height={150} className='r-10' src='https://picsum.photos/300/120'></img> */}
                    </div>
                    
                    <div className='mt-3'>
                        <div className='nu_btn nu_btn--darkgreen w-100'>
                            <div className='nu_btn-text'>Asignarme el turno</div>
                        </div>
                    </div>

                    <div className='mt-3'>
                            <div className='f-22 bold'>
                            CUAP Sagrera
                        </div>
                    </div>

                        <div className='d-flex mt-3'>

                            <div className='me-3'>
                                <div className='d-flex mt-0'>
                                    <div className='nu-icon nu-icon--mid'>
                                        <img width="80%" src='/assets/calendar_solid.svg'></img>
                                    </div>
                                    <div className='ps-2'>
                                        <div className='f-14 bold'>Fecha</div>
                                        <div className='f-12'>24 de Febrero 2024</div>
                                    </div>
                                </div>

                                <div className='d-flex mt-3'>
                                    <div className='nu-icon nu-icon--mid'>
                                        <img width="80%" src='/assets/clock_solid.svg'></img>
                                    </div>
                                    <div className='ps-2'>
                                        <div className='f-14 bold'>Hora de inicio</div>
                                        <div className='f-12 '>12:00 AM</div>
                                        
                                    </div>
                                </div>
                            </div>

                            <div className='ms-3'>

                                <div className='d-flex mt-0'>
                                    <div className='nu-icon nu-icon--mid'>
                                        <img width="80%" src='/assets/calendar_clock_solid.svg'></img>
                                    </div>
                                    <div className='ps-2'>
                                        <div className='f-14  bold'>Turno de</div>
                                        <div className='f-12 '>Mañana</div>
                                    </div>
                                </div>

                                <div className='d-flex mt-3'>
                                    <div className='nu-icon nu-icon--mid'>
                                        <img width="65%" src='/assets/euro_symbol_solid.svg'></img>
                                    </div>
                                    <div className='ps-2'>
                                        <div className='f-14 bold'>500.00</div>
                                        <div className='f-12 opacity-0'>empty</div>
                                    
                                    </div>
                                </div>

                            </div>
                        </div>

                    <div className='mt-5'>

                
                        <div className='mt-3'>
                            <div className='f-22 bold'> Detalle del  turno</div>
                            <div className='f-14'><span className='bold'>Organización</span>: Nombre</div>
                            <div className='f-14'><span className='bold'>Nombre del centro</span>: Hospital de Mataro</div>
                            <div className='f-14'><span className='bold'>Tipo de centro</span>: Hospital</div>
                            <div className='f-14'><span className='bold'>Inicio del turno</span>: 12:00 AM</div>
                            <div className='f-14'><span className='bold'>Fin del turno</span>: 19:00 PM</div>
                            <div className='f-14'><span className='bold'>Unidad Asistencial</span>: Unidad</div>
                            <div className='f-14'><span className='bold'>Ámbito Profesional</span>: Ambito</div>
                        </div>

                        <div className='mt-3'>
                            <div className='f-22 bold'>Información del centro</div>
                            <div className='f-14'><span className='bold'>Parking</span>: Texto Libre</div>
                            <div className='f-14'><span className='bold'>Dietas</span>: Texto Libre</div>
                            <div className='f-14'><span className='bold'>Uniforme</span>: Texto Libre</div>
                            <div className='f-14'><span className='bold'>Punto de encuentro</span>: Texto Libre</div>
                            <div className='f-14'><span className='bold'>Notas</span>: Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500.</div>

                        </div>

                    </div>
                </div>
            </div>
    
        
        </div>
    </>
  )
}
