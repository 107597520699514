import React, { useContext, useEffect, useState } from 'react'
import { IoMdClose, IoMdDocument, IoMdStar } from "react-icons/io";
import { MdCheck, MdOutlineEditCalendar, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import NuCalendar, { NuCalendarProfesional } from '../../components/nu_calendar';
import { IoIosSearch } from "react-icons/io";
import { ProfesionalSideMenu } from '../../components/nu_sidemenu';
import {NuTurnoItem} from '../../components/nu_turno_item';
import { AuthContext } from '../../context/auth_context';
import { Service } from '../../services/api';
import { GlobalContext } from '../../context/global_context';
import { dM, dMY, timeOfDay, UTCDateObj, UTCDateParser } from '../../utils';
import Renderif from '../../utils/renderif';
import TurnoDetailView from '../commun/turno_detail_view';
import NuLoading from '../../components/nu_loading';
import { tab } from '@testing-library/user-event/dist/tab';
import { NotificationContext } from '../../context/notification_context';
import { useNavigate } from 'react-router-dom';
import { NuPickerAssitanceUnit, NuPickerCenterType, NuPickerHorario, NuPickerProfessionalField, NuPickerProvincia } from '../../components/nu_picker';
import { fi } from 'date-fns/locale';
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoCloseCircle } from 'react-icons/io5';
import { darkBlue } from '../../styles/colors';
import { LiaClinicMedicalSolid } from 'react-icons/lia';
import { CiMedicalCross } from 'react-icons/ci';
import NuSelect from '../../components/nu_select';

export default function HomeProfesionalView() {
    const {user,user_data, setUserData} = useContext(AuthContext);


  
    const navigate = useNavigate()

    const {
        renderTitle, 
        getCenter, 
        getAssistanceUnit,
        getProfessionalField,
        getSpecialization,
        getCenterType,
        documents,
        getRating,
        assistanceUnit,
        professionalField,
        center
    } = useContext(GlobalContext)

    const {notify} = useContext(NotificationContext)

    const [state, setState] = useState({
        show_turno_detail:false,
        show_menu:false,
        show_validation_widget:false,
        show_sugerencias_widget:false,
        show_calendario_widget:false,
        show_filtros_widget:false,
        
        turnos:[],
        nextTurno:[],
        suggestedTurno:null,
        turnoSelected:null,
        daySelected: null,
        monthSelected: new Date().getMonth(),
        tab:-1,
        provincias:[],
        show_request:false,
        show_request_general:false,
        show_request_general_assitanceUnit:"",
        show_request_general_professionalFiled:"",
        show_request_general_center:"",
        show_request_general_step:0,

        show_request_general_datetime:"",
        show_request_general_horario:"",
        show_request_general_comments: "",

        shift_requested:false,
        availability:user_data?.nurseData?.availability === null ? '' : user_data?.nurseData?.availability,
        filtered_center:[],
      
        centersProfessionalFields:[],
        centersAssistaceUnits:[],
        center_prefered:null,
        center_preferences:[]
       

    })

    useEffect(()=>{
        setState({...state, loading:true})
        
        init();
        return () => {}
    },[])

    const init = async () => {
        let today = new Date()
        today.setHours('23','00','00')


        if(user_data === null){

      
            setUserData();
        }
        let _suggestedTurno = null;
        let _turnos = await Service('GET', `shift/getMatches`)
        console.log(_turnos) 
    
        _turnos.data.list?.map((item)=>{

            if(item.id == _turnos.data.suggested){
                _suggestedTurno = item
            }

           

        })

        let _turnos_user = await Service('GET', `shift/getAll?idUser=${user_data?.id}`); 

        if(_turnos_user.status === 200){
            _turnos_user.data.list?.sort(function(a,b){return a.date - b.date})

            let turnos_user = []

            _turnos_user.data?.list?.map((item)=>{

                if(item.id == _turnos.data.suggested){
                    _suggestedTurno = item
                }

                if(!checkCompleted(item)){
                    turnos_user.push(item)
                }

            })
            
            setState({
                ...state, 
                loading:false,
                turnos: [..._turnos.data.list, ...turnos_user],
                nextTurno: turnos_user,
                suggestedTurno:_suggestedTurno
            })

        }else{
            setState({
                ...state, 
                loading:false,
                turnos: [],
                nextTurno: [],
                suggestedTurno:_suggestedTurno
            })
        }
     
        

        
    };

    const filter = () => {    

     

        if(state.daySelected === null ){
            if(state.tab === -1){
                return state.turnos.sort(function(a,b){return a.date - b.date});
            }

            
            return state.turnos.sort(function(a,b){return a.date - b.date}).filter((item)=> item.timeOfDay === state.tab.toString());
           
        }   

        
        let _date_selected = UTCDateObj(state.daySelected.toUTCString())


        let _turnos = state.turnos.filter((item) => {

            let _turno = UTCDateObj(new Date(item.date).toUTCString())
           
    
            if(
            _turno.month === _date_selected.month &&
            _turno.date === _date_selected.date &&
            _turno.year === _date_selected.year ){
                return true;
            }
            return false;
            
        }) 


        if(state.tab === -1){
            return _turnos
        }
       
        _turnos = _turnos.filter((item)=> item.timeOfDay === state.tab.toString())
      
        return _turnos;
    }
    const filterProvincia = (turnos) => {

        if(state.provincias.length === 0){
            return turnos;
        }

        let _turnos = []
        
        turnos.map((turno,i)=>{

        


            let find = state.provincias.find((item) => item.provincia === turno.center?.province)
            if(find !== undefined){
                _turnos.push(turno)
            }
            
            
        })
        return _turnos;
    }
  

    const HubSpotForm = () => {
        useEffect(() => {
          const script = document.createElement("script");
          script.src = "//js-eu1.hsforms.net/forms/embed/v2.js";
          script.async = true;
          script.defer = true;
          document.body.appendChild(script);
    
          script.onload = () => {
            if (window.hbspt) {
              window.hbspt.forms.create({
                region: "eu1",
                portalId: "143484882",
                formId: "68d3ace9-2756-4002-81c7-64ef76125e57",
                target: "#hubspotForm",
              });
            }
          };
    
          return () => {
            document.body.removeChild(script);
          };
        }, []);
    
        return <div id="hubspotForm"></div>;
    };

    const checkCompleted = (item) => {
    
        let _today = new Date();
        var end_turno = parseInt(item?.initialHour.split(':')[0])
        var today = new Date(Date.UTC(_today.getFullYear(), _today.getMonth(), _today.getDate(), 0,0,0)).getTime()
        let _date = new Date(item?.date).getTime()

        if(today > _date){
            return true
        }
        if(today < _date){
            return false;
        }
        if(today === _date){
            if(_today.getHours() > end_turno){
                return true
            }
            return false;
        }
    }

    const onRequestShift = async () => {
            setState({...state, loading:true})
            let body = {
                idAssistanceUnit:state.turnoSelected?.idAssistanceUnit,
                idUser:user.id,
                status:1,
                idCenter:state.turnoSelected?.idCenter,
                idOrganization:state.turnoSelected?.idOrganization,
                idShift:state.turnoSelected?.id
            }
            let update_user = user_data.nurseData;
            update_user.availability = state.availability;
            console.log(update_user);
    
            let _update = await Service('PUT', 'nurseData/update', update_user);
            let _onRequest = await Service('POST', 'solicitud/create', body);
            
            if(_onRequest.status === 200 && _update.status === 201){
                state.show_turno_detail=false
                state.show_request = false
                state.shift_requested = true
                // setState({...state, show_turno_detail:false, show_request:false,  shift_requested:true})
            }else{
                notify({title:'¡Solicitar turno!', message:'No ha sido posible solicitar este turno.', type:'error'})
            }
            init()
    }
    const getProfessionalFieldPrecennces = async () => {

        let _center_professional_fields = await Service('GET', `organization_preferences/centers/professionalFields`);
        console.log(_center_professional_fields)

    
        state.centersProfessionalFields = _center_professional_fields.data.list
        // state.center_prefered = centerGroup; 
        setState({...state})

    }
    const getAssistanceUnitPreferences = async () => {


        let _center_assistance_unit = await Service('GET', `organization_preferences/centers/assistanceUnits?idProfessionalField=${state.show_request_general_professionalFiled}`);
        console.log(_center_assistance_unit.data.list)
        state.centersAssistaceUnits = _center_assistance_unit.data.list
        // state.center_prefered = centerGroup; 
        setState({...state})
    }


    const filteredAssitanceUnits = (centers) => {
        centers.map((center)=>{
            console.log(state.center_prefered[center.id])
        })
    }

    const filteredCenter = async () => {

        let centers = []


          

      


        state.centersAssistaceUnits.map((center)=>{

            if(!centers.some((item)=>item.idCenter === center.idCenter)){

                if(state.show_request_general_assitanceUnit !== ""){
                    if(center.idAssistanceUnit === parseInt(state.show_request_general_assitanceUnit)){
                        centers.push(center)
                    }
                }else{
                    centers.push(center)
                }
                
            }
        })

        state.filtered_center = centers;
        setState({...state})

    }

    const fillProfessionalFields = () => {
        console.log(state.center_preferences)
        return []
    }

    const centerType = (idCenter) => {
        let _center = center.find((center) => center.id === parseInt(idCenter))
        console.log(_center)
        if(_center?.idCenterType === 2){
            return true
        }else{
            return false
        }
    }
    
    const onGeneralRequest = async () => {

        setState({...state, loading:true})
        let _organization = center.find((center) => center.id === parseInt(state.show_request_general_center))
        let body = {
            idAssistanceUnit:parseInt(state.show_request_general_assitanceUnit),
            idUser:user.id,
            status:1,
            idCenter:parseInt(state.show_request_general_center),
            idOrganization:_organization.idOrganization,
            comments:state.show_request_general_comments,
            preferred_shift_time:state.show_request_general_horario,
            preferred_date:state.show_request_general_datetime
        }
        if( state.show_request_general_professionalFiled !== ""){
            body.idProfessionalField = parseInt(state.show_request_general_professionalFiled)
        }
        
        // let update_user = user_data.nurseData;
        // update_user.availability = state.show_request_general_datetime + ", " + state.show_request_general_horario + ". " + state.show_request_general_comments;
    

        // let _update = await Service('PUT', 'nurseData/update', update_user);
        let _onRequest = await Service('POST', 'professional_request/create', body);
        console.log(_onRequest)
        
        if(_onRequest.status === 200){

            state.show_request_general = false
            state.show_request_general_assitanceUnit = ""
            state.show_request_general_professionalFiled = ""
            state.show_request_general_step = 0
            state.show_request_general_center  = ""
            state.show_request_general_comments = ""
            state.show_request_general_datetime = ""
            state.show_request_general_horario = ""

            notify({title:'¡Solicitar Acogida!', message:'Solicitud de acogida enviada exitosamente', type:'success'})
        }else{
            notify({title:'¡Solicitar turno!', message:'No ha sido posible solicitar este turno.', type:'error'})
        }
        init()

      
       
    }
   return (
    <>
     <Renderif isTrue={state.loading}>
        <NuLoading></NuLoading>
    </Renderif>
    <div className='container-fluid overflow-hidden d-flex h-100'>


        <ProfesionalSideMenu onClose={()=>{setState({...state, show_menu:false})}} show={state.show_menu} page={'dashboard'}/>


        <div className='container-fluid  overflow-auto hide-scroll col mx-auto'>
            <div className='bg-white sticky-top pt-1 pt-md-3 px-1 px-md-3'>
                <div className='nu-snack'>

                    <div>
                        <div className='f-22 text-white bold'>Hola, {user?.name}</div>
                        <div className='d-flex align-items-center'>
                            <div className='nu-icon nu-icon--mid'>
                                <IoMdStar color='#FFB054' size={24}></IoMdStar>
                            </div>
                            <div className='f-16 text-white'>{getRating().rate} ( {getRating().valoraciones} reseñas)</div>
                        </div>
                    </div>
                    <div className='col'></div>
                    <div className='avatar avatar--small d-block d-md-none' style={{backgroundImage: `url(${user?.picture})`}}></div>
                    <div className='avatar avatar--mid d-none d-md-block' style={{backgroundImage: `url(${user?.picture})`}}></div>

                    <div className='nu-snack-overlay'></div>
                    <div className='ms-2 d-block d-sm-none'>
                        <div className='nu-icon nu-icon--mid cursor-pointer' onClick={()=>{
                            setState({...state, show_menu:true})
                        }}>
                            <BsThreeDotsVertical size={50} color='white'></BsThreeDotsVertical>
                        </div>
                    </div>
                </div>                      
            </div>


            <div className='d-flex d-md-none px-1 py-2'>
             

                    <div className='nu_btn nu_btn--primary' onClick={()=>{ setState({...state, show_validation_widget:!state.show_validation_widget})}}>
                        <div className='nu_btn-text'>Validación</div>
                    </div>
                    
                    <div className='ms-1'></div>
           
                    <div className='nu_btn nu_btn--primary' onClick={()=>{ setState({...state, show_sugerencias_widget:!state.show_sugerencias_widget})}}>
                        <div className='nu_btn-text'>Sugerencias</div>
                    </div>
                    <div className='ms-1'></div>
                    <div className='nu_btn nu_btn--primary' onClick={()=>{ setState({...state, show_calendario_widget:!state.show_calendario_widget})}}>
                        <div className='nu_btn-text'>Calendario</div>
                    </div>

                 
              
            </div>

            {/*<div className='container-fluid container-xl mx-auto d-flex mt-2 px-3'>
                <div className='col'></div>
                <div className='nu_btn nu_btn--overlay' onClick={()=>{
                    getProfessionalFieldPrecennces()
                    state.show_request_general = true
                    state.show_request_general_assitanceUnit = ""
                    state.show_request_general_professionalFiled = ""
                    state.show_request_general_step = 0
                    state.show_request_general_center  = ""
                    state.show_request_general_comments = ""
                    state.show_request_general_datetime = ""
                    state.show_request_general_horario = ""
                    setState({...state})
                }}>
                    <div className='nu_btn-text f-16'>Solicitar acogida</div>
                    <div className='nu_btn-overlay'></div>
                </div>
            </div>*/}

            <div className='container-fluid container-xl mx-auto  bg-white px-1 px-md-3 py-0 mt-2'>
                <div className='row d-flex '>

                        <div className='col-12 col-md-3 flex-column d-none d-md-flex'>
                            {/* <div className='f-22 text-dark bold mb-2 ps-1'>Validación</div> */}
                            {ValidationWidget()}
                        </div>
                    <Renderif isTrue={state.show_validation_widget}>
                        <div className='col-12 col-md-3 d-flex d-md-none flex-column'>
                            {/* <div className='f-22 text-dark bold mb-2 ps-1'>Validación</div> */}
                            {ValidationWidget()}
                        </div>
                    </Renderif>
                    

                        <div className='col-12 col-md-9 d-none d-md-flex flex-column ps-0 ps-md-3 mt-4 mt-md-0 '>       
                                    
                            <div className='d-flex align-items-center '>
                                
                                {/* <div className={`f-22 text-dark bold mb-2 ps-1 ${state.nextTurno.length === 0 ? 'opacity-0' : ''}`}>Tu siguiente turno confirmado</div> */}

                                <div className='col'></div>
                                {/* <div className='d-flex'>
                                    <div className='nu-icon nu-icon--big  cursor-pointer'><SlArrowLeft size={20} /></div>
                                    <div className='nu-icon nu-icon--big  cursor-pointer'><SlArrowRight size={20}  /></div>
                                </div> */}
                            </div>

                            <Renderif isTrue={state.nextTurno.length === 0 && !state.loading}>
                                <div className='col bg-overlay p-3 r-10 d-flex align-items-center position-relative overflow-hidden'>           
                                    <div className='text-white bold ps-3'>No tienes turnos asignados. Estamos trabajando para publicar los turnos que mejor se adapten a ti.</div>                           
                                </div>
                            </Renderif>

                            <Renderif isTrue={state.nextTurno.length > 0 && !state.loading}>
                                <div className='col bg-darkblue p-3 r-10'>
                                <div className='f-22 text-white line-1 bold'>{renderTitle(state.nextTurno[0]?.idCenter, state.nextTurno[0]?.type)}</div>

                                <div className='d-flex flex-wrap'>

                                    <div className='me-3'>
                                        <div className='d-flex mt-4'>
                                            <div className='nu-icon nu-icon--mid'>
                                                <img width="" src='/assets/calendar.svg'></img>
                                            </div>
                                            <div className='ps-2'>
                                                <div className='f-14 text-white bold'>Fecha</div>
                                                <div className='f-12 text-white'>{UTCDateParser(new Date(state.nextTurno[0]?.date).toUTCString(), true)}</div>
                                            </div>
                                        </div>

                                        <div className='d-flex mt-3'>
                                            <div className='nu-icon nu-icon--mid'>
                                                <img width="" src='/assets/clock.svg'></img>
                                            </div>
                                            <div className='ps-2'>
                                                <div className='f-14 text-white bold'>Hora de inicio</div>
                                                <div className='f-12 text-white'>{state.nextTurno[0]?.initialHour} hrs.</div>
                                            
                                            </div>
                                        </div>
                                    </div>

                                    <div className='ms-3'>

                                        <div className='d-flex mt-4'>
                                            <div className='nu-icon nu-icon--mid'>
                                                <img width="" src='/assets/calendar_clock.svg'></img>
                                            </div>
                                            <div className='ps-2'>
                                                <div className='f-14 text-white bold'>Turno de</div>
                                                <div className='f-12 text-white'>{timeOfDay(state.nextTurno[0]?.timeOfDay)}</div>
                                            </div>
                                        </div>

                                        <div className='d-flex mt-3'>
                                            <div className='nu-icon nu-icon--mid'>
                                                <img width="65%" src='/assets/euro_symbol.svg'></img>
                                            </div>
                                            <div className='ps-2'>
                                                <div className='f-14 text-white bold'>{state.nextTurno[0]?.remuneration}</div>
                                                <div className='f-12 text-white opacity-0'>empty</div>
                                        
                                            </div>
                                        </div>

                                    </div>                            
                                    <div className='col'></div>                           
                                    <div className='d-flex justify-content-end align-items-end mt-3 mt-md-0'>
                                        <div className='nu_btn nu_btn--primary' onClick={()=>{
                                            setState({...state, show_turno_detail:true, turnoSelected:state.nextTurno[0]})
                                        }}>
                                            <div className='nu_btn-text'>Ver turno</div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </Renderif>
                        </div>
                    <Renderif isTrue={!state.show_calendario_widget && !state.show_validation_widget && !state.show_sugerencias_widget}>
                        <div className='col-12 col-md-9 d-flex d-md-none flex-column ps-0 ps-md-3 mt-4 mt-md-0 '>                   
                            <div className='d-flex align-items-center '>
                                
                                <div className={`f-22 text-dark bold mb-2 ps-1 ${state.nextTurno.length === 0 ? 'opacity-0' : ''}`}>Tu siguiente turno confirmado</div>

                                <div className='col'></div>
                                <div className='d-flex'>
                                    <div className='nu-icon nu-icon--big  cursor-pointer'><SlArrowLeft size={20} /></div>
                                    <div className='nu-icon nu-icon--big  cursor-pointer'><SlArrowRight size={20}  /></div>
                                </div>
                            </div>

                            <Renderif isTrue={state.nextTurno.length === 0 && !state.loading}>
                                <div className='col bg-overlay p-3 r-10 d-flex align-items-center position-relative overflow-hidden'>           
                                    <div className='text-white bold ps-3'>No tienes turnos asignados. Estamos trabajando para publicar los turnos que mejor se adapten a ti.</div>                           
                                </div>
                            </Renderif>

                            <Renderif isTrue={state.nextTurno.length > 0 && !state.loading}>
                                <div className='col bg-darkblue p-3 r-10'>
                                <div className='f-22 text-white line-1 bold'>{renderTitle(state.nextTurno[0]?.idCenter, state.nextTurno[0]?.type)}</div>

                                <div className='d-flex flex-wrap'>

                                    <div className='me-3'>
                                        <div className='d-flex mt-4'>
                                            <div className='nu-icon nu-icon--mid'>
                                                <img width="" src='/assets/calendar.svg'></img>
                                            </div>
                                            <div className='ps-2'>
                                                <div className='f-14 text-white bold'>Fecha</div>
                                                <div className='f-12 text-white'>{UTCDateParser(new Date(state.nextTurno[0]?.date).toUTCString(), true)}</div>
                                            </div>
                                        </div>

                                        <div className='d-flex mt-3'>
                                            <div className='nu-icon nu-icon--mid'>
                                                <img width="" src='/assets/clock.svg'></img>
                                            </div>
                                            <div className='ps-2'>
                                                <div className='f-14 text-white bold'>Hora de inicio</div>
                                                <div className='f-12 text-white'>{state.nextTurno[0]?.initialHour} hrs.</div>
                                            
                                            </div>
                                        </div>
                                    </div>

                                    <div className='ms-3'>

                                        <div className='d-flex mt-4'>
                                            <div className='nu-icon nu-icon--mid'>
                                                <img width="" src='/assets/calendar_clock.svg'></img>
                                            </div>
                                            <div className='ps-2'>
                                                <div className='f-14 text-white bold'>Turno de</div>
                                                <div className='f-12 text-white'>{timeOfDay(state.nextTurno[0]?.timeOfDay)}</div>
                                            </div>
                                        </div>

                                        <div className='d-flex mt-3'>
                                            <div className='nu-icon nu-icon--mid'>
                                                <img width="65%" src='/assets/euro_symbol.svg'></img>
                                            </div>
                                            <div className='ps-2'>
                                                <div className='f-14 text-white bold'>{state.nextTurno[0]?.remuneration}</div>
                                                <div className='f-12 text-white opacity-0'>empty</div>
                                        
                                            </div>
                                        </div>

                                    </div>                            
                                    <div className='col'></div>                           
                                    <div className='d-flex justify-content-end align-items-end mt-3 mt-md-0'>
                                        <div className='nu_btn nu_btn--primary' onClick={()=>{
                                            setState({...state, show_turno_detail:true, turnoSelected:state.nextTurno[0]})
                                        }}>
                                            <div className='nu_btn-text'>Ver turno</div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </Renderif>
                        </div>
                    </Renderif>

                    
                  

                </div>
            </div>

            <div className='container-fluid container-xl mx-auto  d-flex flex-wrap p-1 p-md-3 bg-white '>

                <div className='col-12 col-md-3'>
                    <div className='py-0 py-md-3 '>

                        
                        <div className='d-none d-md-block'>
                            <NuCalendarProfesional turnos={state.turnos} onChange={(value)=>{
                                if(value === null){
                                    state.daySelected = null
                                }else{
                                
                                    state.daySelected = new Date(value);
                                }
                                setState({...state})
                            }}></NuCalendarProfesional>
                        </div>
                        <Renderif isTrue={state.show_calendario_widget}>
                            <div className='d-block d-md-none'>
                                <NuCalendarProfesional turnos={state.turnos} onChange={(value)=>{
                                    if(value === null){
                                        state.daySelected = null
                                    }else{
                                    
                                        state.daySelected = new Date(value);
                                    }
                                    setState({...state})
                                }}></NuCalendarProfesional>
                            </div>
                        </Renderif>




                        <div className='p-2 d-none d-md-block'>

                            <NuPickerHorario onChangeHorario={(value)=>{
                                // init()
                                setState({...state, tab:value})
                            }}></NuPickerHorario>

                            <NuPickerProvincia onChangeProvincias={(values)=>{
                            
                                setState({...state, provincias:values})
                           }}>
                           </NuPickerProvincia>

                              <NuPickerCenterType onChangePreferences={()=>{
                                init()
                            }}></NuPickerCenterType>

                            <NuPickerAssitanceUnit onChangePreferences={()=>{
                                init()
                            }}></NuPickerAssitanceUnit>

                            <NuPickerProfessionalField onChangePreferences={()=>{
                                init()
                            }}></NuPickerProfessionalField>

                 
                          

                        </div>
                        <Renderif isTrue={state.show_calendario_widget}>
                        <div className='p-2 d-block d-md-none'>

                            <NuPickerHorario onChangeHorario={(value)=>{
                                // init()
                                setState({...state, tab:value})
                            }}></NuPickerHorario>

                            <NuPickerProvincia onChangeProvincias={(values)=>{
                            
                                setState({...state, provincias:values})
                           }}>
                           </NuPickerProvincia>

                              <NuPickerCenterType onChangePreferences={()=>{
                                init()
                            }}></NuPickerCenterType>

                            <NuPickerAssitanceUnit onChangePreferences={()=>{
                                init()
                            }}></NuPickerAssitanceUnit>

                            <NuPickerProfessionalField onChangePreferences={()=>{
                                init()
                            }}></NuPickerProfessionalField>

                 
                          

                        </div>
                        </Renderif>




                        <div className='d-none d-md-block'>
                            <div className='d-flex align-items-end py-2 mt-4'>
                                <div className='f-20 bold line-1'>Sugerencia para ti:</div>
                                <div className='col'></div>
                            </div>

                            <Renderif isTrue={state.suggestedTurno === null}>
                                <div className='text-gray f-12 bold'>Sin sugerencias</div>
                            </Renderif>
                        
                            <Renderif isTrue={state.suggestedTurno !== null}>
                                <div className='col bg-darkblue p-3 r-10'>
                                    <div className='f-20 text-white line-1 bold'>{renderTitle(state.suggestedTurno?.idCenter, state.suggestedTurno?.type)}</div>

                                    <div className='d-flex flex-wrap'>

                                        <div className='me-3'>
                                            <div className='d-flex mt-4'>
                                                <div className='nu-icon nu-icon--mid'>
                                                    <img width="" src='/assets/calendar.svg'></img>
                                                </div>
                                                <div className='ps-2'>
                                                    <div className='f-14 text-white bold'>Fecha</div>
                                                    <div className='f-12 text-white'>{UTCDateParser(new Date(state.suggestedTurno?.date).toUTCString(), true)}</div>
                                                </div>
                                            </div>

                                            <div className='d-flex mt-3'>
                                                <div className='nu-icon nu-icon--mid'>
                                                    <img width="" src='/assets/clock.svg'></img>
                                                </div>
                                                <div className='ps-2'>
                                                    <div className='f-14 text-white bold'>Hora de inicio</div>
                                                    <div className='f-12 text-white'>{state.suggestedTurno?.initialHour} hrs.</div>
                                                
                                                </div>
                                            </div>
                                        </div>

                                        <div className=''>

                                            <div className='d-flex mt-4'>
                                                <div className='nu-icon nu-icon--mid'>
                                                    <img width="" src='/assets/calendar_clock.svg'></img>
                                                </div>
                                                <div className='ps-2'>
                                                    <div className='f-14 text-white bold'>Turno de</div>
                                                    <div className='f-12 text-white'>{timeOfDay(state.suggestedTurno?.timeOfDay)}</div>
                                                </div>
                                            </div>

                                            <div className='d-flex mt-3'>
                                                <div className='nu-icon nu-icon--mid'>
                                                    <img width="65%" src='/assets/euro_symbol.svg'></img>
                                                </div>
                                                <div className='ps-2'>
                                                    <div className='f-14 text-white bold'>{state.suggestedTurno?.remuneration}</div>
                                                    <div className='f-12 text-white opacity-0'>empty</div>
                                            
                                                </div>
                                            </div>

                                        </div>                            
                                                            
                                        
                                    </div>

                                    <div className=''>
                                        <div className='nu_btn nu_btn--primary mt-3' onClick={()=>{
                                            setState({...state, show_turno_detail:true, turnoSelected:state.suggestedTurno})
                                        }}>
                                            <div className='nu_btn-text'>Ver turno</div>
                                        </div>
                                    </div>
                                </div>
                            </Renderif>
                        </div>
                        <Renderif isTrue={state.show_sugerencias_widget}>
                        <div className='d-block d-md-none'>
                            <div className='d-flex align-items-end py-2 mt-4'>
                                <div className='f-20 bold line-1'>Sugerencia para ti:</div>
                                <div className='col'></div>
                            </div>

                            <Renderif isTrue={state.suggestedTurno === null}>
                                <div className='text-gray f-12 bold'>Sin sugerencias</div>
                            </Renderif>
                        
                            <Renderif isTrue={state.suggestedTurno !== null}>
                                <div className='col bg-darkblue p-3 r-10'>
                                    <div className='f-20 text-white line-1 bold'>{renderTitle(state.suggestedTurno?.idCenter, state.suggestedTurno?.type)}</div>

                                    <div className='d-flex flex-wrap'>

                                        <div className='me-3'>
                                            <div className='d-flex mt-4'>
                                                <div className='nu-icon nu-icon--mid'>
                                                    <img width="" src='/assets/calendar.svg'></img>
                                                </div>
                                                <div className='ps-2'>
                                                    <div className='f-14 text-white bold'>Fecha</div>
                                                    <div className='f-12 text-white'>{UTCDateParser(new Date(state.suggestedTurno?.date).toUTCString(), true)}</div>
                                                </div>
                                            </div>

                                            <div className='d-flex mt-3'>
                                                <div className='nu-icon nu-icon--mid'>
                                                    <img width="" src='/assets/clock.svg'></img>
                                                </div>
                                                <div className='ps-2'>
                                                    <div className='f-14 text-white bold'>Hora de inicio</div>
                                                    <div className='f-12 text-white'>{state.suggestedTurno?.initialHour} hrs.</div>
                                                
                                                </div>
                                            </div>
                                        </div>

                                        <div className=''>

                                            <div className='d-flex mt-4'>
                                                <div className='nu-icon nu-icon--mid'>
                                                    <img width="" src='/assets/calendar_clock.svg'></img>
                                                </div>
                                                <div className='ps-2'>
                                                    <div className='f-14 text-white bold'>Turno de</div>
                                                    <div className='f-12 text-white'>{timeOfDay(state.suggestedTurno?.timeOfDay)}</div>
                                                </div>
                                            </div>

                                            <div className='d-flex mt-3'>
                                                <div className='nu-icon nu-icon--mid'>
                                                    <img width="65%" src='/assets/euro_symbol.svg'></img>
                                                </div>
                                                <div className='ps-2'>
                                                    <div className='f-14 text-white bold'>{state.suggestedTurno?.remuneration}</div>
                                                    <div className='f-12 text-white opacity-0'>empty</div>
                                            
                                                </div>
                                            </div>

                                        </div>                            
                                                            
                                        
                                    </div>

                                    <div className=''>
                                        <div className='nu_btn nu_btn--primary mt-3' onClick={()=>{
                                            setState({...state, show_turno_detail:true, turnoSelected:state.suggestedTurno})
                                        }}>
                                            <div className='nu_btn-text'>Ver turno</div>
                                        </div>
                                    </div>
                                </div>
                            </Renderif>
                        </div>
                        </Renderif>


                    </div>
                </div>

                <div className="col-12 col-md-9">
                    <div className='bg-white sticky-top-header py-2 ps-3'>
                        <div className='d-flex align-items-center'>
                            <div className='f-22 bold'>{state.daySelected === null ? '' : UTCDateParser(new Date(state.daySelected).toUTCString(), true)}</div>
                            <div className='col'></div>

                            <div className='d-flex align-items-center'>
                                <div className='dot dot--lightblue'></div>
                                <div className='f-12 bold'>Cobertura</div>

                                <div className='dot dot--orange'></div>
                                <div className='f-12 bold'>Acogida</div>

                                <div className='dot dot--darkgreen'></div>
                                <div className='f-12 bold'>Asignado</div>                       
                            </div>
                            <div className='d-flex'>
                                <div className='nu-icon nu-icon--big  cursor-pointer'><SlArrowLeft size={20} onClick={()=>{
                                    setState({...state, daySelected:new Date(state.daySelected.setDate(state.daySelected.getDate() - 1))})
                                }} /></div>
                                <div className='nu-icon nu-icon--big  cursor-pointer'><SlArrowRight size={20} onClick={() => {
                                    setState({...state, daySelected:new Date(state.daySelected.setDate(state.daySelected.getDate() + 1))})
                                }}  /></div>
                            </div>
                        </div>
                        {/* <div className='d-flex'>

                            <div className={`nu-tab nu-tab--${state.tab === -1 ? 'active':''} me-2`} onClick={()=>{setState({...state, tab:-1})}}>Todos</div>
                            <div className={`nu-tab nu-tab--${state.tab === 0 ? 'active':''} me-2`} onClick={()=>{setState({...state, tab:0})}}>Mañana</div>
                            <div className={`nu-tab nu-tab--${state.tab === 2 ? 'active':''} me-2`} onClick={()=>{setState({...state, tab:2})}}>Tarde</div>
                            <div className={`nu-tab nu-tab--${state.tab === 3 ? 'active':''} me-2`} onClick={()=>{setState({...state, tab:3})}}>Noche</div>
                            <div className={`nu-tab nu-tab--${state.tab === 1 ? 'active':''} me-2`} onClick={()=>{setState({...state, tab:1})}}>Día 12h</div>                          
                        </div> */}
                    </div>

                    <div className='ps-1 ps-md-3'>                       

                        <Renderif isTrue={filterProvincia(filter()).length === 0}>
                            <div id='' className='w-100 bg-gray-00 r-10 py-5 px-5 d-flex'>
                                <div className='col px-3'>
                                    <div className='bold f-14 text-white bg-darkblue p-2 text-center r-10 mt-5 px-5 position-relative'>
                                        <div className='bubble-arrow'></div>
                                        ¡Ups! Parece que se han agotado los turnos que coincidían con tus criterios de elección, experiencia y formación.</div>
                                
                                <div className='d-flex justify-content-center'>
                                    <div className='bold f-14 text-gray p-2 text-center r-10 mt-4 px-5'>Seguimos trabajando para que pronto puedas ver más.</div>
                                </div>
                                    
                                </div>

                                
                                <div className='col-4 pt-2'>
                                    <img width={200} src='/assets/nurse_empty.png'></img>
                                </div>
                            </div>
                            {/* <div className="col"><HubSpotForm /></div> */}
                        </Renderif>
                        {
                            filterProvincia(filter()).map((item,i)=>{ 
                                if(item.profession == '0'){ //Turno de enfermeria
                                
                                    if(user_data?.idUserType === 1){
                                    
                                        return (
                                            <NuTurnoItem item={item} key={i} onClick={()=>{
                                                setState({...state, show_turno_detail:true, turnoSelected:item})
                                            }}></NuTurnoItem>
                                        )
                                    }

                                } 
                                if(item.profession == '1'){ //TCAE
                                    if(user_data?.idUserType === 3){
                                        return (
                                            <NuTurnoItem item={item} key={i} onClick={()=>{
                                                setState({...state, show_turno_detail:true, turnoSelected:item})
                                            }}></NuTurnoItem>
                                            );
                                        }
                                        }
                                    })}
                    </div>
                </div>
           
            </div>
        </div>

    
        <TurnoDetailView active = {state.show_turno_detail} turno={state.turnoSelected} 
        
        onRequest={()=>{
            setState({...state, show_request:true})
        }}
        onClose={()=>{
            setState({...state, show_turno_detail:false})
        }} onAssing={(evt)=>{
            if(evt){
                state.turnoSelected.idUser = user.id;
                state.turnoSelected.status = "1";

                filter().map((turno)=>{
                    if(turno.id === state.turnoSelected.is){
                        turno.idUser = user.id;
                        turno.status = '1'
                    }
                })
                setState({...state, show_turno_detail:false})

                notify({title:'¡Listo!', message:'Turno asignado exitosamente.', type:'success'})
                
            }else{
                notify({title:'¡Lo sentimos!', message:'Ocurrio un error al asingar turno.', type:'error'})
            }
        }}></TurnoDetailView>

        <Renderif isTrue={state.show_request}>

        <div className='nu-modal'>

            <div className='nu-modal-body fit-content'>
                <div className='d-flex'>
                    <div className='col'></div>
                    <div className='nu-icon nu-icon--big cursor-pointer' onClick={()=>{
                        setState({...state, show_request:false})
                    }}>
                        <IoCloseCircle color={darkBlue} size={30}></IoCloseCircle>
                    </div>
                </div>
                <div className='col d-flex flex-column '>
                    
                    <div className='f-22 bold'>El turno que has solicitado requiere <br></br>una Acogida previa </div>

                    <div className='bg-white shadow-sm py-2 px-1 my-1 me-2 r-8 d-flex flex-row align-items-center mt-3'>

                        <div className='d-flex align-items-center  pe-3'>
                            <div className='nu-icon nu-icon--mid'>
                            <LiaClinicMedicalSolid color='#0D78BF'></LiaClinicMedicalSolid>
                            </div>
                            <div className='f-12 bold ellipsis'>{state.turnoSelected?.center?.name}</div>
                        </div>

                        <div className='d-flex align-items-center pe-3'>
                                <div className='nu-icon nu-icon--mid'>
                                    <img width="63%" src='/assets/calendar_solid.svg'></img>
                                </div>
                            
                                
                                <div className='f-12 bold ellipsis'>{UTCDateParser(new Date(state.turnoSelected?.date).toUTCString(), true)}</div>
                            
                        </div>

                        <div className='d-flex align-items-center  pe-3'>
                            <div className='nu-icon nu-icon--mid'>
                                <img width="70%" src='/assets/clock_solid.svg'></img>
                            </div>
                            <div className='f-12 bold ellipsis'>{state.turnoSelected?.initialHour} - {state.turnoSelected?.finalHour}</div>
                        </div>

                        <div className='d-flex align-items-center  pe-3'>
                            <div className='nu-icon nu-icon--mid'>
                                <MdOutlineEditCalendar color='#0D78BF'></MdOutlineEditCalendar>
                            </div>
                            <div className='f-12 bold ellipsis'>Cobertura</div>
                        </div>

                        <div className='d-flex align-items-center  pe-3'>
                            <div className='nu-icon nu-icon--mid'>
                                <CiMedicalCross color='#0D78BF'></CiMedicalCross>
                            </div>
                            <div className='f-12 bold ellipsis'>{getProfessionalField(state.turnoSelected?.idProfessionalField)}</div>
                        </div>

                    </div>

                    <div className='f-14 bold mt-3'>Acogida requerida: <span className='text-darkblue'>{getAssistanceUnit(state.turnoSelected?.idAssistanceUnit)}</span> </div>
                    <div className='f-14 mt-3'>
                    Te reservaremos este turno mientras el Centro te informa si tu solicitud ha sido aceptada o denegada y te contactará para acordar la Acogida. <br></br><br></br>Si no eres contactado, puedes escribirnos a hola@nursea.es y te ayudaremos con todo lo que necesites.
                    </div>

                    <div className='f-14 bold mt-3'>Indícanos tu disponibilidad para publicarte un turno de Acogida: </div>
                <div className='pe-2'>
                <textarea rows={5} value={state.availability} className='nu-input f-14 ' placeholder='Describe cual es tu disponibilidad. Fecha y Hora de tu preferencia.' onChange={(evt)=>{
                        setState({...state, availability:evt.currentTarget.value})
                    }}></textarea>

                </div>
                    <div className='f-14 bold mt-3'>Otros turnos solicitados en la misma Unidad Asistencial o Centro: </div>
                    <div className='f-14'>Te reservamos estos turnos. Se te asignarán cuando tengas asignada la Acogida.</div>

                    <div className='f-14 mt-3 text-gray'>No has solicitado más turnos.</div>

                    {
                        state.turnos.filter((turno)=>turno.idUser === user.id && turno.idAssistanceUnit === state.turnoSelected?.idAssistanceUnit && turno.idCenter === state.turnoSelected?.idCenter  ).map((item, i)=>{
                            return (
                                <div key={i} className='bg-white shadow-sm py-2 px-1 my-1 me-2 r-8 d-flex flex-wrap flex-row align-items-center'>

                                    <div className='d-flex align-items-center  pe-3'>
                                        <div className='nu-icon nu-icon--mid'>
                                        <LiaClinicMedicalSolid color='#0D78BF'></LiaClinicMedicalSolid>
                                        </div>
                                        <div className='f-12 bold ellipsis'>{item?.center?.name}</div>
                                    </div>
        
                                    <div className='d-flex align-items-center pe-3'>
                                            <div className='nu-icon nu-icon--mid'>
                                                <img width="63%" src='/assets/calendar_solid.svg'></img>
                                            </div>
                                        
                                            
                                            <div className='f-12 bold ellipsis'>{UTCDateParser(new Date(item?.date).toUTCString(), true)}</div>
                                        
                                    </div>
        
                                    <div className='d-flex align-items-center  pe-3'>
                                        <div className='nu-icon nu-icon--mid'>
                                            <img width="70%" src='/assets/clock_solid.svg'></img>
                                        </div>
                                        <div className='f-12 bold ellipsis'>{item?.initialHour} - {item?.finalHour}</div>
                                    </div>
        
                                    <div className='d-flex align-items-center  pe-3'>
                                        <div className='nu-icon nu-icon--mid'>
                                            <MdOutlineEditCalendar color='#0D78BF'></MdOutlineEditCalendar>
                                        </div>
                                        <div className='f-12 bold ellipsis'>Cobertura</div>
                                    </div>
        
                                    <div className='d-flex align-items-center  pe-3'>
                                        <div className='nu-icon nu-icon--mid'>
                                            <CiMedicalCross color='#0D78BF'></CiMedicalCross>
                                        </div>
                                        <div className='f-12 bold ellipsis'>{getProfessionalField(item?.idProfessionalField)}</div>
                                    </div>
        
                                </div>
                            )
                        })
                    }

                    <div className='d-flex  pe-2 mt-4'>

                        <div className='col'></div>

                        <div className='nu_btn me-2' onClick={()=>{
                            setState({...state, show_request:false})
                        }}>
                            <div className='nu_btn-text f-14'>Cancelar</div>
                            {/* <div className='nu_btn-overlay'></div> */}
                        </div>

                        

                        <div className='nu_btn nu_btn--overlay' onClick={()=>{
                            // navigate('/clinic')
                            console.log(state.turnoSelected)
                            onRequestShift()
                            // setState({...state, show_request:false, shift_requested:true})
                        }}>
                            <div className='nu_btn-text f-14'>Solicitar turno</div>
                            <div className='nu_btn-overlay'></div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        </Renderif>

        <Renderif isTrue={state.show_request_general}>

            <div className='nu-modal'>

                <div className='nu-modal-body fit-content'>
                    <div className='d-flex'>
                        <div className='col'></div>
                        <div className='nu-icon nu-icon--big cursor-pointer' onClick={()=>{
                            setState({...state, show_request_general:false})
                        }}>
                            <IoCloseCircle color={darkBlue} size={30}></IoCloseCircle>
                        </div>
                    </div>
                    <div className='col d-flex flex-column'>

                        <div className='f-22 text-center bold'>Solicitar turno de Acogida</div>

                        <div className='d-flex align-items-center mt-5 mx-5'>

                            <div className={`step ${state.show_request_general_step >= 0 ? 'step--active':''}`} >1</div>
                            <div className={`step-line ${state.show_request_general_step >= 1 ? 'step-line--active':''}`}></div>

                            <div className={`step ${state.show_request_general_step >= 1 ? 'step--active':''}`} >2</div>
                            <div className={`step-line ${state.show_request_general_step >= 2  ? 'step-line--active':''}`}></div>

        

                            <div className={`step ${state.show_request_general_step >= 2 ? 'step--active':''}`} >3</div>
                            <div className={`step-line ${state.show_request_general_step >= 3  ? 'step-line--active':''}`}></div>


                            <div className={`step ${state.show_request_general_step >= 3 ? 'step--active':''}`} >4</div>


                        </div>


                           <div className='mt-5'>

                           


            
                            <Renderif isTrue={state.show_request_general_step === 0}>
                                <div className='f-14 bold'>Ámbito Profesional</div>
                                <NuSelect value={state.show_request_general_professionalFiled} onChange={(evt) =>{

                                           state.show_request_general_professionalFiled = evt.currentTarget.value;
                                            //  state.show_request_general_step = 3
                                             setState({...state})
                                 
                                }}>
                                  
                                    <option value={""}>Seleccionar</option>
                                     {state.centersProfessionalFields.map((item, i)=>{


                                        if(item?.idProfessionalField === null) return;
                                        if(item?.idProfessionalField === 1) return;
                                       
                                        return (
                                            <option key={i} value={item?.idProfessionalField}>{item?.professionalField?.description}</option>
                                        )
                                    })}
                                    
                                </NuSelect>
                            </Renderif>

                            <Renderif isTrue={state.show_request_general_step === 1}>
                                <div className='f-14 bold'>Unidad asistencial (Opcional)</div>
                                <NuSelect value={state.show_request_general_assitanceUnit} onChange={(evt) =>{
                                    state.show_request_general_assitanceUnit = evt.currentTarget.value;
                               
                                    setState({...state})
                                }}>
                                    <option value={""}>Seleccionar</option>
                                    {state.centersAssistaceUnits.map((item, i)=>{
                                        if(item.idAssistanceUnit === null) return;
                                        if(item.idAssistanceUnit === 1) return;

                                        return (
                                            <option key={i} value={item?.idAssistanceUnit}>{item?.assistanceUnit?.description}</option>
                                        )
                                    })}
                                    
                                </NuSelect>
                            </Renderif>

                            <Renderif isTrue={state.show_request_general_step === 2}>
                                <div className='f-14 bold'>Centro</div>
                                <NuSelect value={state.show_request_general_center} onChange={(evt) =>{
                                    state.show_request_general_center = evt.currentTarget.value;
                                  
                                    setState({...state})
                                }}>
                                    <option value={""}>Seleccionar</option>
                                     {state.filtered_center.map((item, i)=>{
                                     
                                        return (
                                            <option key={i} value={item?.center?.id}>{item?.center?.name}</option>
                                        )
                                    })}
                                    
                                </NuSelect>
                            </Renderif>


                            <Renderif isTrue={state.show_request_general_step === 3}>

                                <div className='f-14 text-center'>Centro te informará si tu solicitud ha sido aceptada o denegada <br></br> 
                                y te contactará para acordar la Acogida.</div>
                                <div className='f-14 text-center mt-2'>
                                    Si no eres contactado, puedes escribirnos a <span className='bold'>hola@nursea.es</span> <br></br> y te ayudaremos con todo lo que necesites
                                </div>

                                <div className='f-14 text-center mt-3'><span className='bold'>Indícanos tu disponibilidad para publicarte un turno de Acogida</span> (Opcional)</div>
                                <div className='mt-3'></div>

                                <div className='f-14'>Fecha y hora (Opcional)</div>
                                <input type='date' value={state.show_request_general_datetime} onChange={(evt)=>{
                                   
                                    state.show_request_general_datetime = evt.currentTarget.value;
                                    setState({...state})

                                }} className='nu-input'></input>
                                <div className='f-14 mt-3'>Horario del turno (Opcional)</div>
                                 <NuSelect value={state.show_request_general_horario} onChange={(evt)=>{
                                             
                                             state.show_request_general_horario = evt.currentTarget.value;
                                             setState({...state})
                                 }}>
                                     <option value="">Selecciona</option>
                                     <option value="Mañana">Mañana</option>
                                     <option value="Tarde">Tarde</option>
                                     <option value="Noche">Noche</option>
                                     <option value="Dia 12h">Dia 12h</option>
                                     <option value="Guardia 24h">Guardia 24h</option>
                                </NuSelect>

                                <div className='f-14 mt-3'>Indica algún comentario (Opcional) </div>
                                <textarea value={state.show_request_general_comments} onChange={(evt)=>{
                                    state.show_request_general_comments = evt.currentTarget.value
                                    setState({...state})
                                }} className='nu-input'></textarea>


                       
                            </Renderif>


                           </div>

                            
                        
                        
                  
                       
                      

                        <div className='d-flex  pe-2 mt-4'>

                            <div className='col'></div>

                            <Renderif isTrue={state.show_request_general_step !== 0}>
                                <div className='nu_btn me-2' onClick={()=>{

                                    setState({...state, show_request_general_step:state.show_request_general_step - 1})

                                }}>
                                    <div className='nu_btn-text f-14'>Atrás</div>
                                    {/* <div className='nu_btn-overlay'></div> */}
                                </div>
                            </Renderif>

                            

                            <div className='nu_btn nu_btn--overlay' onClick={()=>{
                                // navigate('/clinic')
                                if(state.show_request_general_step === 3){
                                    onGeneralRequest()
                                }else{
                                switch (state.show_request_general_step) {
                                    case 0:
                                        if(state.show_request_general_professionalFiled === ''){
                                            notify({title:'Solicitar Acogida', message:'Selecciona un Ámbito Profesional', type:'error'})
                                            break;
                                        }
                                        state.show_request_general_step = state.show_request_general_step + 1
                                        getAssistanceUnitPreferences()
                                    
                                        break;
                                    case 1:
                                        state.show_request_general_step = state.show_request_general_step + 1
                                        filteredCenter()
                                        break;
                                    
                                    case 2:
                                        if(state.show_request_general_center === ''){
                                            notify({title:'Solicitar Acogida', message:'Selecciona un centro', type:'error'})
                                            break;
                                        }
                                        state.show_request_general_step = state.show_request_general_step + 1
                                        break;
                                
                                    default:
                                        break;
                                }
                             

                                setState({...state})
                                }
                             
                                // setState({...state, show_request:false, shift_requested:true})
                            }}>
                                <div className='nu_btn-text f-14'>Siguiente</div>
                                <div className='nu_btn-overlay'></div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </Renderif>

        <Renderif isTrue={state.shift_requested}>

        <div className='nu-modal'>

            <div className='nu-modal-body'>
                <div className='d-flex'>
                    <div className='col'></div>
                    <div className='nu-icon nu-icon--big cursor-pointer' onClick={()=>{
                        setState({...state, shift_requested:false})
                    }}>
                        <IoCloseCircle color={darkBlue} size={30}></IoCloseCircle>
                    </div>
                </div>
                <div className='col d-flex flex-column justify-content-center align-items-center'>
                    <img width="70px" src='/assets/tick-circle.png'></img>
                    <div className='f-22 bold text-center'>¡Turno solicitado!</div>
                    <div className='f-14 text-center'>Te reservaremos este turno mientras el Centro te informa
si tu solicitud ha sido aceptada o denegada y te contactará para
acordar la Acogida. Puedes continuar solicitando turnos.</div>
                    <div className='nu_btn nu_btn--overlay mt-3' onClick={()=>{
                        setState({...state, shift_requested:false})
                        // navigate('/profesional/turnos')
                    }}>
                        <div className='nu_btn-text f-18'>Ver más turnos</div>
                        <div className='nu_btn-overlay'></div>
                    </div>
                </div>

            </div>
        </div>

        </Renderif>


        
   

 
  
     
    </div>
    </>
  )
}

function ValidationWidget() {
    const {
        documents,
        loadingDocuments
    } = useContext(GlobalContext)
    const {
        user_data
    } = useContext(AuthContext)

    const navigate = useNavigate();

  return (
    <div className='col d-flex flex-column justify-content-center bg-dark r-10 py-3 '>

        <Renderif isTrue={loadingDocuments}>
            <div className="spinner-border text-light mx-auto"></div>
        </Renderif>

        <Renderif isTrue={!loadingDocuments}>
            <Renderif isTrue={documents.length >= 8 && user_data?.status === '1'}>
                <div className='f-14 text-white text-center mt-2'>Completado</div>
            </Renderif>

            <Renderif isTrue={documents.length >= 8 && user_data?.status === '0'}>
                <div className='f-16 text-white text-center bold mb-1'>En revisión</div>
            </Renderif>

            <Renderif isTrue={documents.length < 8 && user_data?.status === '0'}>
                <div className='f-16 text-white text-center bold mb-1'>Pendiente</div>
            </Renderif>

            <div className='position-relative d-flex justify-content-center align-items-center py-4'>
                <img className='position-absolute' src='/assets/circle.png'></img>
                <div className='text-white text-center bold'>{documents?.length}/8</div>
            </div>


            <Renderif isTrue={documents.length >= 8 && user_data?.status === '1'}>
                <div className='f-14 text-white text-center mt-2'>¡Todo listo!</div>
            </Renderif>
            <Renderif isTrue={documents.length < 8}>
                <div className='f-12 text-white text-center mt-2 px-1'>Recuerda subir la documentación para que podamos validar tu perfil.</div>
            </Renderif>

            <Renderif isTrue={documents.length < 8}>
                
                    <div className='mx-auto mt-2'>
                        <div className='nu_btn nu_btn--primary' onClick={()=>{
                            navigate('/profesional/documentacion')
                        }}>
                            <div className='nu-icon'>
                                <IoMdDocument size={14}></IoMdDocument>

                            </div>
                            <div className='nu_btn-text f-12'>Ver documentos</div>
                        </div>
                    </div>
            </Renderif>
        </Renderif>
    </div>
  )
}

