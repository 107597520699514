import CheckBox from "../../../nu_checkbox";
import React from "react";

const checkProfessionalFieldOther = (centerPreferences, idProfessionalField) => {
  return centerPreferences.some((pref) => pref.idProfessionalField === idProfessionalField)
}

export const ClinicShiftRequestPreferences = ({
  organizationId,
  centerId,
  professionalField,
  centerPreferences,
  onAddPreference,
  onDeletePreference,
  reloadPreferences
}) => {
  return (
    <div className='container-fluid d-flex flex-wrap mt-4'>
      {professionalField.map((item, i)=>{
        if(item.id === 1) return;
        return (
          <div className='col-4 mt-1'>
            <CheckBox
              key={i}
              checked={checkProfessionalFieldOther(centerPreferences, item.id)}
              text={item.description}
              value={item.id}
              onChange={(evt)=> {
                if(evt.value === true){
                  onAddPreference({
                    organizationId: organizationId,
                    centerId: centerId,
                    idAssistanceUnit: null,
                    idProfessionalField: item.id,
                    reloadPreferences: reloadPreferences
                  })
                } else {
                  onDeletePreference({
                    centerPreferences: centerPreferences,
                    organizationId: organizationId,
                    idAssistanceUnit: null,
                    idProfessionalField: item.id,
                    reloadPreferences: reloadPreferences
                  })
                }
              }} />
          </div>
        )
      })}
    </div>
  );
};