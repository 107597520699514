import React, {useEffect, useState} from "react";
import Calendar from "react-calendar";
import {UTCDateParser} from "../../utils";
import Renderif from "../../utils/renderif";
import {es} from "date-fns/locale";
import {fetchShifts} from "../../services/api";
import {format} from "date-fns";
import {getStartOfCurrentMonth, getStartOfNextMonth} from "../../utils/dates";

const formatMonthYear  = (date, locale) => {
  return format(date, 'MMMM yyyy', { locale: es });
}

const formatShortWeekday = (locale, date) => {
  return format(date, 'eee', { locale: es });
};

export function ShiftCalendar({disableDots, onChange = []}) {

  const [state, setState] = useState({
    active:false,
    dateActive:null,
    currentMonthShifts: []
  })

  const updateShifts = async ({startDate, endDate}) => {
    state.currentMonthShifts = await fetchShifts({startDate, endDate});
    setState({...state});
  };

  useEffect(() => {
    const currentMonth = getStartOfCurrentMonth();
    const nextMonth = getStartOfNextMonth(currentMonth);
    updateShifts({
      startDate: currentMonth,
      endDate: nextMonth
    });

    return () => {
      // Perform cleanup if necessary
    };
  }, []);

  return (
    <div className={`p-0 w-100 ${state.active ? 'bv-calendar-v1':'bv-calendar'}`}>
      <Calendar
        allowPartialRange={false}
        showNeighboringMonth={false}
        formatMonthYear={(locale, date) => formatMonthYear(date, es)}
        formatShortWeekday={formatShortWeekday}
        tileClassName={['bv-calendar-day']}
        tileContent={(tile)=>{
          var e = Date.UTC(tile.date.getFullYear(), tile.date.getMonth(), tile.date.getDate(), 0,0,0)
          let _tile_date = UTCDateParser(new Date(e).toUTCString())

          let _today = new Date();
          var today = new Date(Date.UTC(_today.getFullYear(), _today.getMonth(), _today.getDate(), 0,0,0))

          let dated = [];
          state.currentMonthShifts.map((turno)=>{
            let _turno_date = UTCDateParser(new Date(turno.date ).toUTCString())
            if(_turno_date === _tile_date){
              dated.push(turno)
            }
          })

          let dots = [];
          if( e < today.getTime() ){
            if(dated.some((item) => item.idUser !== null)){
              dots.push('darkblue');
            }
          } else {
            if(dated.some((item) => item.idUser !== null)){
              dots.push('green');
            }

            if(dated.some((item) => item.type === '0')){
              dots.push('orange');
            }

            if(dated.some((item) => item.type === '1')){
              dots.push('lightblue');
            }
          }

          return (
            <Renderif isTrue={dated.length > 0}>
              <div className='dot-dates'>
                <Renderif isTrue={!disableDots}>
                {dots.map((item, i)=>{
                  return (
                    <div key={i} className={`dot-date dot-date--${item}`}></div>
                  )
                })}
                </Renderif>
              </div>
            </Renderif>
          )
        }}
        onChange={(evt)=>{

          var e = Date.UTC(evt.getFullYear(), evt.getMonth(), evt.getDate(), 0,0,0)

          if (state.dateActive === null) {
            state.dateActive = evt
            state.active = true;
            onChange(e)
          } else {

            if(state.dateActive.toString() === evt.toString()){
              state.active = false;
              state.dateActive = null
              onChange(null)
            } else {
              state.dateActive = evt
              onChange(e)
            }
          }

          setState({...state})
        }}

        onActiveStartDateChange={async (evt)=>{
          const nextMonth = getStartOfNextMonth(evt.activeStartDate);
          await updateShifts({
            startDate: evt.activeStartDate,
            endDate: nextMonth
          });
        }}

      ></Calendar>
    </div>
  )
}
