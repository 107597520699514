import CheckBox from "../../../nu_checkbox";
import Renderif from "../../../../utils/renderif";
import React, {useState} from "react";
import {Service} from "../../../../services/api";

const checkAssistanceUnit = (centerPreferences, idAssistanceUnit) => {
  return centerPreferences.some((pref) => pref.idAssistanceUnit === idAssistanceUnit && pref.idProfessionalField === null)
}

const checkProfessionalField = (centerPreferences, idAssistanceUnit, idProfessionalField) => {
  return centerPreferences.some((pref) => pref.idAssistanceUnit === idAssistanceUnit && pref.idProfessionalField === idProfessionalField)
}

export const HospitalShiftRequestPreferences = ({
  organizationId,
  centerId,
  centerTypeId,
  centerPreferences,
  professionalField,
  assistanceUnit,
  onAddPreference,
  onDeletePreference,
  reloadPreferences
}) => {

  const [state, setState] = useState({
    loading_preferences: false
  });

  const onAddAllProfessionalField = async ({professionalField, centerPreferences, organizationId, centerId, idAssistanceUnit, onFinish}) => {
    for (let i = 0; i < professionalField.length; i++) {
      if(professionalField[i].id !== 1){
        let _pref = centerPreferences.some((pref) => pref.idAssistanceUnit === idAssistanceUnit && pref.idProfessionalField === professionalField[i].id);
        if(!_pref) {
          let body = {
            idOrganization: organizationId,
            idAssistanceUnit:idAssistanceUnit,
            idProfessionalField:professionalField[i].id,
            idCenter: centerId
          }
          await Service('POST', 'organization_preferences/create', body);
        }
      }
    }
    onFinish(organizationId)
  }

  const onDeleteAllProfessionalField = async ({professionalField, centerPreferences, organizationId, idAssistanceUnit, onFinish}) => {
    for (let i = 0; i < professionalField.length; i++) {
      if(professionalField[i].id !== 1){
        let _pref = centerPreferences.some((pref) => pref.idAssistanceUnit === idAssistanceUnit && pref.idProfessionalField === professionalField[i].id);
        if(_pref){
          let __pref =  centerPreferences.find((pref)=> pref.idAssistanceUnit === idAssistanceUnit && pref.idProfessionalField === professionalField[i].id)
          await Service('DELETE', `organization_preferences/delete/${__pref.id}`);
        }
      }
    }
    onFinish(organizationId)
  }

  return (
    <div className='container-fluid mt-4'>
      {assistanceUnit.map((item, i)=>{
        if(item.id === 1) {return}
        if(item.id === 14) {return}
        if(item.id === 21) {return}
        return (
          <div className='mt-1'>
            <CheckBox
              key={i}
              checked={checkAssistanceUnit(centerPreferences, item.id)}
              text={item.description}
              value={item.id}
              onChange={(evt)=> {
                if(evt.value === true){
                  onAddPreference({
                    organizationId: organizationId,
                    centerId: centerId,
                    idAssistanceUnit: item.id,
                    idProfessionalField: null,
                    reloadPreferences: reloadPreferences
                  })
                } else {
                  onDeletePreference({
                    centerPreferences: centerPreferences,
                    organizationId: organizationId,
                    idAssistanceUnit: item.id,
                    idProfessionalField: null,
                    reloadPreferences: reloadPreferences
                  })
                }
              }}
            />

            <Renderif isTrue={centerTypeId === 2 && checkAssistanceUnit(centerPreferences, item.id)}>
              <div className=' bg-gray-00 p-3 d-flex flex-wrap r-10 mt-2 position-relative overflow-hidden'>
                <Renderif isTrue={state.loading_preferences === item.id}>
                  <div className='nu-loader'>
                    <div className="spinner-border text-light"></div>
                  </div>
                </Renderif>
                <div className='col-4'>
                  <CheckBox
                    checked={centerPreferences.filter((pr) => pr.idAssistanceUnit === item.id).length >= professionalField.length}
                    text={'Recibir solicitudes en todos los ámbitos'}
                    value={-1}
                    onChange={(evt)=> {
                      setState({...state, loading_preferences: item.id})
                      if(evt.value){
                        onAddAllProfessionalField({
                          professionalField: professionalField,
                          centerPreferences: centerPreferences,
                          organizationId: organizationId,
                          centerId: centerId,
                          idAssistanceUnit: item.id,
                          onFinish: (organizationId) => {
                            setState({...state, loading_preferences: false});
                            reloadPreferences(organizationId);
                          }
                        });

                      } else {
                        onDeleteAllProfessionalField({
                          professionalField: professionalField,
                          centerPreferences: centerPreferences,
                          idAssistanceUnit: item.id,
                          organizationId: organizationId,
                          onFinish: (organizationId) => {
                            setState({...state, loading_preferences: false});
                            reloadPreferences(organizationId);
                          }
                        })
                      }
                    }}
                  />
                </div>
                {professionalField.map((_item, j)=>{
                  if(_item.id === 1) return;
                  return (
                    <div className='col-4'>
                      <CheckBox
                        key={j}
                        checked={checkProfessionalField(centerPreferences, item.id, _item.id)}
                        text={_item.description}
                        value={_item.id}
                        onChange={(evt)=> {
                          if(evt.value === true){
                            onAddPreference({
                              organizationId: organizationId,
                              centerId: centerId,
                              idAssistanceUnit: item.id,
                              idProfessionalField: _item.id,
                              reloadPreferences: reloadPreferences
                            })
                          } else {
                            onDeletePreference({
                              centerPreferences: centerPreferences,
                              organizationId: organizationId,
                              idAssistanceUnit: item.id,
                              idProfessionalField: _item.id,
                              reloadPreferences: reloadPreferences
                            })
                          }
                        }}
                      />
                    </div>
                  )
                })}
              </div>
            </Renderif>
          </div>
        )
      })}
    </div>
  );
};