export const getStartOfCurrentDay = () => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
};

export const getStartOfNextDay = (day) => {
    return new Date(day.getFullYear(), day.getMonth(), day.getDate() + 1, 0, 0, 0);
};

export const getStartOfCurrentMonth = () => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), 1);
};

export const getStartOfNextMonth = (month) => {
    const nextMonth = new Date(month.getFullYear(), month.getMonth() + 1, 1);
    return new Date(nextMonth.getFullYear(), nextMonth.getMonth() + 1, 1);
};

