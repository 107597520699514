import React, { useContext, useEffect, useState } from 'react'
import { IoIosArrowBack, IoMdCheckboxOutline, IoMdStar } from "react-icons/io";
import { MdModeEdit } from "react-icons/md";
import { IoMdSave } from "react-icons/io";
import { ClinicSideMenu } from '../../components/nu_sidemenu';
import { darkBlue } from '../../styles/colors';
import Renderif from '../../utils/renderif';
import { AuthContext } from '../../context/auth_context';
import { GlobalContext } from '../../context/global_context';
import { Service } from '../../services/api';
import { NotificationContext } from '../../context/notification_context';
import NuLoading from '../../components/nu_loading';
import { useFilePicker } from 'use-file-picker';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { RiAddCircleLine } from "react-icons/ri";
import { alphaOrder } from '../../utils';
import { FaClinicMedical } from 'react-icons/fa';
import {ShiftRequestPreferences} from "../../components/clinic/profile/shift_request_preferences";
export default function PerfilClinicaView() {

    const {user, user_data, updateUserPictue, setUserPicture} = useContext(AuthContext)
   

 
    const {notify} = useContext(NotificationContext);
    const {center, loadCenters, centerType, assistanceUnit, professionalField} = useContext(GlobalContext);
    const { openFilePicker, filesContent, loading, plainFiles } = useFilePicker({
        accept: '*',
    });
    

    
    const [state, setState] = useState({
        show_menu:false,
        editing_general:false,
        editing_preferences:null,
        editing_center:-1, 
        editing_requests:false,
        organization:null,
        centers:null,
        password:'',
        passwordConfirmed:'',
        description: null,
        loading:false,
        newCenter: {
            name: '',
            address: '',
            postalCode: '',
            province: '',
        },
        newDescription: {
            parking:'',
            dietas:'',
            uniforme:'',
            location:'',
            notes:'',
        },
        preferences:[]
    })
    const [addNewCenter, setAddNewCenter] = useState(false)
    const provincias = [
        {
            provincia: 'Andalucía',
            colegios: ['Almería', 'Cádiz', 'Córdoba', 'Granada', 'Huelva', 'Jaén', 'Málaga', 'Sevilla']
        },
        {
            provincia: 'Aragón',
            colegios: ['Zaragoza', 'Teruel', 'Huesca']
        },
        {
            provincia: 'Principado de Asturias',
            colegios: ['Asturias']
        },
        {
            provincia: 'Illes Balears',
            colegios: ['Illes Balears']
        },
        {
            provincia: 'Canarias',
            colegios: ['Las Palmas', 'Santa Cruz de Tenerife']
        },
        {
            provincia: 'Cantabria',
            colegios: ['Cantabria']
        },
        {
            provincia: 'Castilla y León',
            colegios: ['Ávila', 'Burgos', 'León', 'Palencia', 'Salamanca', 'Segovia', 'Soria', 'Valladolid', 'Zamora']
        },
        {
            provincia: 'Castilla y la Mancha',
            colegios: ['Albacete', 'Ciudad Real', 'Cuenca', 'Guadalajara y Toledo']
        },
        {
            provincia: 'Cataluña',
            colegios: ['Barcelona', 'Tarragona', 'Girona', 'Lleida']
        },
        {
            provincia: 'Comunidad Valenciana',
            colegios: ['Alicante', 'Valencia', 'Castellón']
        },
        {
            provincia: 'Extremadura',
            colegios: ['Cáceres','Badajoz']
        },
        {
            provincia: 'Galicia',
            colegios: ['A Coruña','Lugo', 'Ourense', 'Pontevedra']
        },
        {
            provincia: 'Murcia',
            colegios: ['Murcia']
        },
        {
            provincia: 'Madrid',
            colegios: ['Madrid']
        },
        {
            provincia: 'Navarra',
            colegios: ['Navarra']
        },
        {
            provincia: 'País Vasco',
            colegios: ['Bizcaia','Gipuzkoa','Araba']
        },
        {
            provincia: 'La Rioja',
            colegios: ['La Rioja']
        },
        {
            provincia: 'Ceuta',
            colegios: ['Ceuta']
        }
        ,
        {
            provincia: 'Melilla',
            colegios: ['Melilla']
        }
        
    ];

    const parseDescription = (description) => {

  
       
        try {
             
            let string = description;
            string = string.replaceAll('<div>', '')
            string=  string.replaceAll('</div>', '",')
            string=  string.replaceAll('<span>', '"')
            string= string.replaceAll('</span>', '"')
            string= string.replaceAll('Punto de encuentro', 'Location')
            string= string.replaceAll(':', ':"')

        
            
            string = string.slice(0, -1);
            string = '{' + string + '}'
            let obj = JSON.parse(string)
            return obj;
        } catch (error) {
            return {
                Parking:'',
                Dietas:'',
                Uniforme:'',
                Location:'',
                Notas:''

            }
        }
       
       
    
    }

    
 

    useEffect(()=>{
        const init = async () => {

            setState({...state, loading:true})
           
            let _organization = await Service('GET', `organizationData/getById/${user_data.id}`)
            state.organization = _organization.data.organizationData;

            let _centers = await loadCenters()
            state.centers = _centers.filter((center) => center.idOrganization === _organization.data.organizationData.id)

            patch_centers();
            // state.centers = center.filter((item)=>item.idOrganization === user_data.id);
            setState({...state, loading:false})
        };
        init();
        return () => {}
    },[])

    useEffect( ()=>{


        const init = async () => {

            if(filesContent.length > 0 && state.active_file_type !== null){     
                setState({...state, loading:true})
                let a = await updateUserPictue(plainFiles[0])
          
                await setUserPicture();
                setState({...state, loading:false})
            }
        }
        init();
        
        return () => {}
    },[filesContent])

    const patch_centers = async () => {

        for (let i = 0; i < state.centers.length; i++) {
           
            if(state.centers[i].description !== null && state.centers[i].info === null){
                
                

                let description_parsed = parseDescription(state.centers[i].description);

                let info_model = {
                    Parking:description_parsed.Parking,
                    Dietas:description_parsed.Dietas,
                    Uniforme:description_parsed.Uniforme,
                    Location:description_parsed.Location,
                    Notas:description_parsed.Notas
                }
                
                let body = {
            
                        id: state.centers[i].id,
                        idOrganization: state.organization.id,
                        name: state.centers[i].name,
                        address: state.centers[i].address,
                        postalCode: state.centers[i].postalCode,
                        province: state.centers[i].province,
                        description: state.centers[i].description,
                        info:JSON.stringify(info_model)
                    
                }
                state.centers[i].info = JSON.stringify(info_model)
                let _updateCenter = await Service('PUT', 'center/update', body);



            }
            
           
            
        }
        setState({...state, loading:false})
    }


    const checkPassword = (password) => {
        var re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-/]).{8,}$/;
        // var re = /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
        return re.test(password);
    }
    const validator = () => {

        state.errors = {}

        if(!checkPassword(state.passwordConfirmed)){
            state.errors.password = 'Debe tener mínimo 8 caracteres, 1 letra Mayúscula, 1 letra minúscula, 1 número y 1 carácter especial (!&%#*?-^/)'
        }
        
        // if(state.passwordConfirmed !== state.password){
        //     state.errors.confirmPassword = 'Las contraseñas no coinciden'
        // }
       
        
        setState({...state})
        if(Object.keys(state.errors).length > 0){
            
            return false;
        }
       return true;
        

    }
    const onUpdateProfile = async () => {
        
        let body = {
            phone: state.organization.phone_number,
            name: state.organization.name,
            cif_nif: state.organization.cif,
            postal_code: state.organization.postal_code,
            address: state.organization.address,
            community: state.organization.community,
            province: state.organization.province,
            contact_name: state.organization.contact_name,
            contact_email: state.organization.email
        }
    
        let update = await Service('PUT', 'user/updateOrganization', body );
       
      
        if(update.status === 201){
            notify({title:"Listo!", message:'Tu datos han sido actualizados', type:'success'})
        }else{
            notify({title:"Error!", message:update?.response?.data.message?.error, type:'error'})
        }
        
        if(state.passwordConfirmed !== ''){

            body = {
                password:state.password,
                new_password:state.passwordConfirmed
            }

            if(!validator()){
           
                return;
            }
            let update_password = await Service('PUT', 'user/updatePassword', body );
          
            if(update_password.status === 201){
                notify({title:"Contraseña", message:'Tu contraseña ha sido actualizada', type:'success'})
            }else{
                notify({title:"Contraseña", message:'No ha sido posible cambiar tu contraseña', type:'error'})
            }
            setState({
                ...state, 
                password:'',
                passwordConfirmed:''
            })

        }
    
    }

    const updateCenter = async () => {
        
        console.log(state.description)

       

        let _center = state.centers.filter((item)=> item.id === state.editing_center)[0]
        let body = {
            
                id: state.editing_center,
                idOrganization: state.organization.id,
                name: _center.name,
                address: _center.address,
                postalCode: _center.postalCode,
                province: _center.province,
                description: _center.description,
                info: JSON.stringify(state.description),
                idCenterType: _center.idCenterType
            
        }
        let _updateCenter = await Service('PUT', 'center/update', body);
   
        if(_updateCenter.status === 201){
            notify({title:"Centro Sanitario", message:'Tu datos han sido actualizados', type:'success'})
        }else{
            notify({title:"Centro Sanitario", message:'No ha sido posible actualizar tus datos.', type:'error'})
        }
        loadCenters()
        refetchData()
        // setState({...state, editing_center:-1, editing_requests:false, description:null })

    }

    const refetchData = async () => {
        setState({...state, loading:true})
       
        let _organization = await Service('GET', `organizationData/getById/${user_data.id}`)
        state.organization = _organization.data.organizationData;
        let _centers = await loadCenters()

        state.centers = _centers.filter((center) => center.idOrganization === _organization.data.organizationData.id)
        setState({...state, loading:false, editing_center:-1, editing_requests:false, description:null })
    
    };

    const validatorCenter = () => {
        state.errors = {}
        
        if(state.newCenter.name === ''){
            state.errors.name = 'Nombre completo es obligatorio'
        }
        if(state.newCenter.postalCode === ''){
            state.errors.postalCode = 'Código postal es obligatorio'
        }
        if(state.newCenter.address === ''){
            state.errors.address = 'Dirección es obligatorio'
        }
        if(state.newCenter.province === ''){
            state.errors.province = 'Provincia es obligatorio'
        }
        if(state.newDescription.parking === ''){
            state.errors.parking = 'Parking es obligatorio'
        }
        if(state.newDescription.dietas === ''){
            state.errors.dietas = 'Dietas es obligatorio'
        }
        if(state.newDescription.uniforme === ''){
            state.errors.uniforme = 'Uniforme es obligatorio'
        }
        if(state.newDescription.location === ''){
            state.errors.location = 'Punto de encuentro es obligatorio'
        }
      
    
        setState({...state})
        if(Object.keys(state.errors).length > 0){
            
            return false;
        }
       return true;
        

    }

    const onCreateCenter = async () => {
        let _description = 
        `<div><span>Parking</span>: ${state.newDescription.parking}</div><div><span>Dietas</span>: ${state.newDescription.dietas}</div><div><span>Uniforme</span>: ${state.newDescription.uniforme}</div><div><span>Punto de encuentro</span>: ${state.newDescription.location}</div><div><span>Notas</span>: ${state.newDescription.notes}</div>`;
        
        if(!validatorCenter()){
            return;
        }
        
        let body = {
            idOrganization: user_data.id,
            name: state.newCenter.name,
            address: state.newCenter.address,
            postalCode: state.newCenter.postalCode,
            province: state.newCenter.province,
            description: null,
            info: JSON.stringify({
                Parking:state.newDescription.parking,
                Dietas:state.newDescription.dietas,
                Uniforme:state.newDescription.uniforme,
                Location:state.newDescription.location,
                Notas:state.newDescription.notes
            }),
            idCenterType: state.newCenter.idCenterType
        }
        setState({...state, loading:true})
        let _addCenter = await Service('POST', 'center/create', JSON.stringify(body) )
        if(_addCenter.status === 201){
            notify({title:'¡Listo!', message:'Se ha dado de alta un nuevo centro.', type:'success'})
            refetchData()
        }else{
            notify({title:'Error', message:_addCenter?.response?.data?.error, type:'error'})
        }
        setAddNewCenter(false)
        setState({...state,
            loading: false,
            newCenter: {
                name: '',
                address: '',
                postalCode: '',
                province: '',
            },
            newDescription: {
                parking:'',
                dietas:'',
                uniforme:'',
                location:'',
                notes:'',
            }
        })
    }

    const _center = (idCenter) => {
        let _center = state.centers?.find((center)=>center.id === idCenter)
        return _center
    }

    const handlePreferencesBackButton = ()=> {
      state.editing_center = -1
      state.editing_requests = false
      state.preferences = []
      setState({...state})
    };

  return (
    <>
    <Renderif isTrue={state.loading}>
        <NuLoading></NuLoading>
    </Renderif>
    <div className='container-fluid overflow-hidden d-flex h-100'>


    
        <ClinicSideMenu onClose={()=>{setState({...state, show_menu:false})}} show={state.show_menu} page='perfil' />
   


        <div className='container-fluid overflow-auto hide-scroll col  mx-auto'>

            <div className='bg-white sticky-top pt-1 pt-md-3 px-1 px-md-3'>
     

                <div className='nu-snack'>
                <div>
                        <div className='f-22 text-white bold'>Hola, {user?.name}</div>
                        <div className='d-flex align-items-center'>
                            <div className='nu-icon nu-icon--mid'>
                                <IoMdStar color='#FFB054' size={24}></IoMdStar>
                            </div>

                            <div className='f-16 text-white'>5.0 (0 reseñas)</div>

                        </div>
                    
                </div>
                <div className='col'></div>
                    <div className='avatar avatar--mid' style={{backgroundImage: `url(${user?.picture})`}}></div>
                    <div className='nu-snack-overlay'></div>
                    <div className='ms-2 d-block d-sm-none'>
                        <div className='nu-icon nu-icon--mid cursor-pointer' onClick={()=>{
                            setState({...state, show_menu:true})
                        }}>
                            <BsThreeDotsVertical size={50} color='white'></BsThreeDotsVertical>
                        </div>
                    </div>
                </div>
                       
            </div>

            <Renderif isTrue={state.editing_requests === true}>
              <ShiftRequestPreferences
                organizationId={user_data.id}
                centerId={parseInt(state.editing_center)}
                centerTypeId={_center(state.editing_center)?.idCenterType}
                centerName={_center(state.editing_center)?.name}
                professionalField={professionalField}
                assistanceUnit={assistanceUnit}
                onBackButton={handlePreferencesBackButton}
              ></ShiftRequestPreferences>
            </Renderif>

            <Renderif isTrue={state.editing_requests === false}>

            <div className='container-fluid container-xl mx-auto p-3'>

                <div className='d-flex flex-column justify-content-start align-items-start p-2'>
                    <div className='f-22 bold mb-3 d-block d-md-block'>Mi Perfil</div>
                
                    <div className='d-flex d-md-none flex-column align-items-center'>
                        <div className='avatar avatar--small' style={{backgroundImage: `url(${user?.picture})`}}></div>
                        <div className='nu-icon nu-icon--mid cursor-pointer' onClick={()=>{
                            openFilePicker()
                        }}>
                            <MdModeEdit color={darkBlue} size={24}></MdModeEdit>
                        </div>
                    </div>
                </div>


                <div className='d-flex'>

                    <div className='d-none d-md-flex flex-column align-items-center pe-3'>
                        <div className='avatar avatar--big' style={{backgroundImage: `url(${user?.picture})`}}></div>
                        <div className='nu-icon nu-icon--mid cursor-pointer' onClick={()=>{
                             openFilePicker()
                        }}>
                            <MdModeEdit color={darkBlue} size={24}></MdModeEdit>
                        </div>
                    </div>

                    <div className='col d-flex flex-column'>
                        <div className='col '>

                            <div className='bg-gray r-10 p-4'>
                                <div className='d-flex'>
                                    <div className='f-22 bold mb-3'>Sobre ti</div>
                                    <div className='col'></div>

                                    <div className='d-flex align-items-center cursor-pointer' onClick={()=>{
                                        setState({...state, editing_general:!state.editing_general})
                                    }}>
                                        <div className='f-14 text-darkblue bold mx-2'>Editar</div>
                                        <div className='nu-icon nu-icon--mid' onClick={()=>{

                                        }}>
                                            <MdModeEdit color={darkBlue} size={24}></MdModeEdit>
                                        </div>
                                    </div>
                                </div>

                                <div className='d-flex flex-wrap justify-content-center align-items-center  mt-2'>
                                    <div className='col-6 col-md'>
                                        <div className='f-16 bold'>Organización</div>
                                    </div>
                                    <div className='col-6 col-md'>
                                        <input disabled={!state.editing_general} defaultValue={state.organization?.name} className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`} onChange={(evt)=>{
                                            state.organization.name = evt.currentTarget.value;
                                            setState({...state})
                                        }}></input>
                                    </div>
                                    <div className='col-6 col-md mt-2 mt-md-0 d-none d-md-block'>
                                    <div className='f-16 ps-md-5 bold'>Contraseña actual:</div>
                                    </div>
                                    <div className='col-6 col-md mt-2 mt-md-0 d-none d-md-block'>
                                    <input disabled={!state.editing_general} value={state.password} className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`} onChange={(evt)=>{
                                        state.password = evt.currentTarget.value;
                                        setState({...state})
                                    }}></input>
                                    </div>
                                </div>

                                <div className='d-flex flex-wrap justify-content-center align-items-center  mt-2'>
                                    <div className='col-6 col-md'>
                                        <div className='f-16 bold'>CIF / NIF</div>
                                    </div>
                                    <div className='col-6 col-md'>
                                        <input disabled={!state.editing_general} defaultValue={state.organization?.cif} className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`} onChange={(evt)=>{
                                            state.organization.cif = evt.currentTarget.value;
                                        }}></input>
                                    </div>
                                    <div className='col-6 col-md mt-2 mt-md-0 d-none d-md-block'>
                                        <div className='f-16 ps-md-5 bold'>Nueva contraseña</div>
                                    </div>
                                    <div className='col-6 col-md mt-2 mt-md-0 d-none d-md-block'>
                                        <input disabled={!state.editing_general}  value={state.passwordConfirmed}  className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`} onChange={(evt)=>{
                                        state.passwordConfirmed = evt.currentTarget.value;
                                        setState({...state})
                                    }}></input>
                                    <div className='text-danger f-12'>{state.errors?.password}</div>
                                    </div>
                                </div>

                                <div className='d-flex flex-wrap justify-content-center align-items-center  mt-2'>
                                    <div className='col-6 col-md'>
                                        <div className='f-16 bold'>Dirección</div>
                                    </div>
                                    <div className='col-6 col-md'>
                                    <input disabled={!state.editing_general} defaultValue={state.organization?.address} className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`} onChange={(evt)=>{
                                            state.organization.address = evt.currentTarget.value;
                                        }}></input>
                                    </div>
                                    <div className='col-6 col-md mt-2 mt-md-0'>
                                        <div className='f-16 ps-md-5 bold'>Personal de referencia contratación:</div>
                                    </div>
                                    <div className='col-6 col-md mt-2 mt-md-0'>
                                    {/* <input className={`nu-input--light p-2 opacity-0 ${!state.editing_general ? 'nu-input--disabled': ''}`}></input> */}
                                    </div>
                                </div>

                                <div className='d-flex flex-wrap justify-content-center align-items-center  mt-2'>
                                    <div className='col-6 col-md'>
                                        <div className='f-16 bold'>Código postal</div>
                                    </div>
                                    <div className='col-6 col-md'>
                                    <input disabled={!state.editing_general} defaultValue={state.organization?.postal_code} className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`} onChange={(evt)=>{
                                            state.organization.postal_code = evt.currentTarget.value;
                                        }}></input>
                                    </div>
                                    <div className='col-6 col-md mt-2 mt-md-0'>
                                        <div className='f-16 ps-md-5 bold'>Nombre</div>
                                    </div>
                                    <div className='col-6 col-md mt-2 mt-md-0'>
                                        <input disabled={!state.editing_general} defaultValue={state.organization?.contact_name} className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`} onChange={(evt)=>{
                                                state.organization.contact_name = evt.currentTarget.value;
                                            }}></input>
                                    </div>
                                </div>

                                <div className='d-flex flex-wrap justify-content-center align-items-center  mt-2'>
                                    <div className='col-6 col-md'>
                                        <div className='f-16 bold'>Comunidad Autónoma</div>
                                    </div>
                                    <div className='col-6 col-md'>

                                    <select 
                                        disabled={!state.editing_general}   
                                        value={state.organization?.community} 
                                        onChange={(evt)=>{
                                            state.organization.community = evt.currentTarget.value;
                                            setState({...state})
                                        }}  
                                        className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`}>

                                        <option value="">Seleccionar</option>
                                        {
                                            provincias.map((item, i)=>{
                                                return(
                                                    <option key={i} value={item.provincia}>{item.provincia}</option>
                                                )
                                            })
                                        }
                            
                                    </select>

                                    </div>
                                    <div className='col-6 col-md mt-2 mt-md-0'>
                                        <div className='f-16 ps-md-5 bold'>Correo electrónico</div>
                                    </div>
                                    <div className='col-6 col-md mt-2 mt-md-0'>
                                        <input disabled defaultValue={user.email} className={`nu-input--light p-2 nu-input--disabled`}></input>
                                    </div>
                                </div>

                                <div className='d-flex flex-wrap justify-content-center align-items-center  mt-2'>
                                    <div className='col-6 col-md'>
                                        <div className='f-16 bold'>Provincia</div>
                                    </div>
                                    <div className='col-6 col-md'>

                                        <select
                                            disabled={!state.editing_general}   
                                            value={state.organization?.province}  
                                            className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`} 
                                            onChange={(evt)=>{
                                                state.organization.province = evt.currentTarget.value;
                                                setState({...state })
                                            }}>
                                            <option value="">Seleccionar</option>
                                            {
                                                provincias.filter((provincia)=> provincia.provincia === state.organization?.community)[0]?.colegios?.map((item, i)=>{

                                                    
                                                    return(
                                                        <option key={i} value={item}>{item}</option>
                                                    )
                                                })
                                            }
                                        </select>


                                    </div>
                                    <div className='col-6 col-md mt-2 mt-md-0'>
                                      
                                    </div>
                                    <div className='col-6 col-md mt-2 mt-md-0'>
                                  
                                    </div>
                                </div>

     


                                <div className='d-flex flex-wrap justify-content-center align-items-center  mt-2'>
                                    <div className='col-6 col-md'>
                                        <div className='f-16 bold'>Teléfono</div>
                                    </div>
                                    <div className='col-6 col-md'>
                                    <input disabled={!state.editing_general} defaultValue={state.organization?.phone_number} className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`} onChange={(evt)=>{
                                            state.organization.phone_number = evt.currentTarget.value;
                                        }}></input>
                                    </div>
                                    <div className='col-6 col-md mt-2 mt-md-0'>
                                        {/* <div className='f-16 ps-5 bold'>Correo electrónico</div> */}
                                    </div>
                                    <div className='col-6 col-md mt-2 mt-md-0'>
                                        {/* province */}
                                    </div>
                                </div>


                                {/* <div className='d-flex flex-wrap justify-content-center align-items-center  mt-2 border'>
                                    <div className='col-6 col-md'>
                                        <div className='f-16 bold'>Contraseña actual:</div>
                                    </div>
                                    <div className='col-6 col-md'>
                                        <input disabled={!state.editing_general} value={state.password} className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`} onChange={(evt)=>{
                                            state.password = evt.currentTarget.value;
                                            setState({...state})
                                        }}></input>
                                    </div>
                                    <div className='col-6 col-md mt-2 mt-md-0'>
                                        <div className='f-16 ps-md-5 bold'>Nueva contraseña</div>
                                    </div>
                                    <div className='col-6 col-md mt-2 mt-md-0'>
                                        <input disabled={!state.editing_general}  value={state.passwordConfirmed}  className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`} onChange={(evt)=>{
                                        state.passwordConfirmed = evt.currentTarget.value;
                                        setState({...state})
                                    }}></input>
                                    <div className='text-danger f-12'>{state.errors?.password}</div>
                                    </div>
                                </div> */}

                                <Renderif isTrue={state.editing_general}>
                                    <div className='d-flex'>
                                        
                                        <div className='col'></div>

                                      

                                        <div className='nu_btn nu_btn--primary ms-2' onClick={()=>{
                                            onUpdateProfile()
                                        }}>
                                            <div className='nu-icon nu-icon--mid me-1'>
                                                <IoMdSave size={18}></IoMdSave>
                                            </div>
                                            <div className='nu_btn-text'>Guardar</div>
                                        </div>
                                    </div>
                                </Renderif>

                            </div>

                        </div>

                        <div className='col'>

                            {state.centers?.map((center, i)=>{
                                // console.log(center)
                                let info;
                                 try {
             
                                    info = JSON.parse(center.info)
                                 
                                  
                                } catch (error) {
                                    console.log(error)
                                    info = {
                                        Parking:'',
                                        Dietas:'',
                                        Uniforme:'',
                                        Location:'',
                                        Notas:''
                        
                                    }
                                }
                                // AQUI
                                return (
                                    <div key={i} className='bg-gray r-10 p-4 mt-4'>
                                    <div className='d-flex align-items-center'>
                                        <div className='f-22 bold mb-3'>Información del Centro Sanitario</div>
                                        <div className='col'></div>

                                        <div className='nu_btn nu_btn--primary me-2' onClick={()=>{
                                            // onUpdateProfile()
                                                state.description = info
                                                state.editing_center = center.id
                                                state.editing_requests = true

                                                let _center = state.centers.find((_center) => _center.id === center.id)
                                                let _preferences = JSON.parse(_center.info)?.Requests
                                                if(_preferences !== undefined){
                                                    state.preferences = _preferences;
                                                } else {
                                                    state.preferences = []
                                                }

                                                setState({...state})

                                        }}>
                                            <div className='nu-icon nu-icon--mid me-1'>
                                                <IoMdCheckboxOutline size={18}></IoMdCheckboxOutline>
                                            </div>
                                            <div className='nu_btn-text'>Recibir solicitudes</div>
                                        </div>
    
                                        <div className='d-flex align-items-center cursor-pointer' onClick={()=>{
                                            state.description = info
                                            setState({...state, editing_center:center.id})
                                        }}>
                                            <div className='f-14 text-darkblue bold mx-2'>Editar</div>
                                            <div className='nu-icon nu-icon--mid' onClick={()=>{
    
                                            }}>
                                                <MdModeEdit color={darkBlue} size={24}></MdModeEdit>
                                            </div>
                                        </div>
                                    </div>
    
                                    <div className='d-flex flex-wrap justify-content-center align-items-center  mt-2'>
                                        <div className='col-4 col-md-2'>
                                            <div className='f-16 pe-5 bold'>Nombre</div>
                                        </div>

                                        <div className='col-8 col-md-4'>
                                            <input 
                                            disabled={state.editing_center === center.id ? false : true} 
                                            defaultValue={state.centers[i].name} 
                                            className={`nu-input--light p-2 ${state.editing_center === center.id ? false : true ? 'nu-input--disabled': ''}`} 
                                            onChange={(evt)=>{
                                                state.centers[i].name = evt.currentTarget.value;
                                            }}></input>
                                        </div>

                                        <div className='col-4 col-md-2'>
                                            <div className='f-16 ps-md-3 bold'>Tipo de Centro</div>
                                        </div>

                                        <div className='col-8 col-md-4 mt-2 mt-md-0'>
                                            <select 
                                            disabled={state.editing_center === center.id ? false : true} 
                                            
                                            value={state.centers[i].idCenterType}
                                            className={`nu-input--light p-2 ${state.editing_center === center.id ? false : true ? 'nu-input--disabled': ''}`} 
                                            onChange={(evt)=>{
                                                state.centers[i].idCenterType = parseInt(evt.currentTarget.value)
                                                setState({...state})
                                            }}>

                                            <option value=''>Seleccionar</option> 
                                                {alphaOrder(centerType, 'description').map((item,i)=>{
                                                if(item.id==1) return;
                                           
                                                
                                                return (
                                                    <option key={i} value={item.id}>{item.description}</option> 
                                                )
                                          
                                        })}
                                            </select>


                                        </div>
                                    </div>
    
                                    <div className='d-flex justify-content-center align-items-center  mt-2'>
                                        <div className='col-4 col-md-2'>
                                            <div className='f-16 pe-5 bold'>Dirección</div>
                                        </div>
                                        <div className='col-8 col-md-10'>
                                             <input 
                                            disabled={state.editing_center === center.id ? false : true} 
                                            defaultValue={state.centers[i].address} 
                                            className={`nu-input--light p-2 ${state.editing_center === center.id ? false : true ? 'nu-input--disabled': ''}`} 
                                            onChange={(evt)=>{
                                                state.centers[i].address = evt.currentTarget.value;
                                            }}></input>
                                        </div>
                                    </div>
    
                
                                    <div className='d-flex flex-wrap justify-content-center align-items-center  mt-2'>

                                        <div className='col-4 col-md-2'>
                                            <div className='f-16 pe-5 bold'>Código postal</div>
                                        </div>

                                        <div className='col-8 col-md-4'>
                                        <input 
                                            disabled={state.editing_center === center.id ? false : true} 
                                            defaultValue={state.centers[i].postalCode} 
                                            className={`nu-input--light p-2 ${state.editing_center === center.id ? false : true ? 'nu-input--disabled': ''}`} 
                                            onChange={(evt)=>{
                                                state.centers[i].postalCode = evt.currentTarget.value;
                                            }}></input>
                                        </div>


                                        <div className='col-4 col-md-2 mt-2'>
                                            <div className='f-16 ps-md-3 bold'>Provincia</div>
                                        </div>

                                        <div className='col-8 col-md-4  mt-2 mt-md-0'>
                                        <select disabled={state.editing_center === center.id ? false : true}   defaultValue={state.centers[i].province} onChange={(evt)=>{
                                        
                                        state.centers[i].province = evt.currentTarget.value;
                                            // state.organization.province = evt.currentTarget.value;
                                            // setState({...state})

                                            }}  className={`nu-input--light p-2 ${state.editing_center === center.id ? false : true ? 'nu-input--disabled': ''}`}>
                                            <option value="" disabled>Seleccionar</option>
                                            {
                                                provincias.map((item, i)=>{
                                                    return(
                                                        <option key={i} value={item.provincia}>{item.provincia}</option>
                                                    )
                                                })
                                            }
                                
                                        </select>
                                        {/* <input 
                                            disabled={state.editing_center === center.id ? false : true} 
                                            defaultValue={state.centers[i].province} 
                                            className={`nu-input--light p-2 ${state.editing_center === center.id ? false : true ? 'nu-input--disabled': ''}`} 
                                            onChange={(evt)=>{
                                                state.centers[i].province = evt.currentTarget.value;
                                            }}></input> */}
                                        </div>
                                    </div>
    
                                    <div className='d-flex justify-content-center align-items-center  mt-2'>
                                        <div className='col-4 col-md-2'>
                                            <div className='f-16 pe-5 bold'>Parking</div>
                                        </div>
                                        <div className='col-8 col-md-10'>
                                        <input 
                                            disabled={state.editing_center === center.id ? false : true} 
                                            // defaultValue={parseDescription(state.centers[i].description)?.Parking?.toString()} 
                                             defaultValue={info?.Parking} 
                                            className={`nu-input--light p-2 ${state.editing_center === center.id ? false : true ? 'nu-input--disabled': ''}`} 
                                            onChange={(evt)=>{
                                                state.description.Parking = evt.currentTarget.value
                                            }}></input>
                                        </div>
                                    </div>
    
                                    <div className='d-flex justify-content-center align-items-center  mt-2'>
                                        <div className='col-4 col-md-2'>
                                            <div className='f-16 pe-5 bold'>Dietas</div>
                                        </div>
                                        <div className='col-8 col-md-10'>
                                        <input 
                                            disabled={state.editing_center === center.id ? false : true} 
                                            // defaultValue={parseDescription(state.centers[i].description)?.Dietas?.toString()} 
                                            defaultValue={info?.Dietas} 
                                            className={`nu-input--light p-2 ${state.editing_center === center.id ? false : true ? 'nu-input--disabled': ''}`} 
                                            onChange={(evt)=>{
                                                state.description.Dietas = evt.currentTarget.value
                                            }}></input>
                                        </div>
                                    </div>
    
                                    <div className='d-flex justify-content-center align-items-center  mt-2'>
                                        <div className='col-4 col-md-2'>
                                            <div className='f-16 pe-5 bold'>Uniforme</div>
                                        </div>
                                        <div className='col-8 col-md-10'>
                                        <input 
                                            disabled={state.editing_center === center.id ? false : true} 
                                            // defaultValue={parseDescription(state.centers[i].description)?.Uniforme?.toString()} 
                                            defaultValue={info?.Uniforme} 
                                            className={`nu-input--light p-2 ${state.editing_center === center.id ? false : true ? 'nu-input--disabled': ''}`} 
                                            onChange={(evt)=>{
                                                state.description.Uniforme = evt.currentTarget.value
                                            }}></input>
                                        </div>
                                    </div>
    
                                    <div className='d-flex justify-content-center align-items-center  mt-2'>
                                        <div className='col-4 col-md-2'>
                                            <div className='f-16 pe-5 bold'>Punto de encuentro</div>
                                        </div>
                                        <div className='col-8 col-md-10'>
                                        <input 
                                            disabled={state.editing_center === center.id ? false : true} 
                                            // defaultValue={parseDescription(state.centers[i].description)?.Location?.toString()} 
                                            defaultValue={info?.Location} 
                                            className={`nu-input--light p-2 ${state.editing_center === center.id ? false : true ? 'nu-input--disabled': ''}`} 
                                            onChange={(evt)=>{
                                                state.description.Location = evt.currentTarget.value
                                            }}></input>
                                        </div>
                                    </div>
    
                                    <div className='d-flex justify-content-center align-items-center  mt-2'>
                                        <div className='col-4 col-md-2'>
                                            <div className='f-16 pe-5 bold'>Notas</div>
                                        </div>
                                        <div className='col-8 col-md-10'>
                                        <input 
                                            disabled={state.editing_center === center.id ? false : true} 
                                            // defaultValue={parseDescription(state.centers[i].description)?.Notas?.toString()} 
                                            defaultValue={info?.Notas} 
                                            className={`nu-input--light p-2 ${state.editing_center === center.id ? false : true ? 'nu-input--disabled': ''}`} 
                                            onChange={(evt)=>{
                                                state.description.Notas = evt.currentTarget.value
                                            }}></input>
                                        </div>
                                    </div>
    
                                    <Renderif isTrue={state.editing_center === center.id ? true : false}>
                                        <div className='d-flex mt-3'>
                                            
                                            <div className='col'></div>


                                           
    
                                            <div className='nu_btn nu_btn--primary ms-2' onClick={()=>{
                                                // setState({...state, show_turno_detail:!state.show_turno_detail})
                                                updateCenter()
                                               
                                            }}>
                                                <div className='nu-icon nu-icon--mid me-1'>
                                                    <IoMdSave size={18}></IoMdSave>
                                                </div>
                                                <div className='nu_btn-text'>Guardar</div>
                                            </div>
                                        </div>
                                    </Renderif>
    
                                    </div>
                                )
                            })}
                            <Renderif isTrue={addNewCenter}>
                                <div className='bg-gray r-10 p-4 mt-4'>
                                    <div className='d-flex'>
                                        <div className='f-22 bold mb-3'>Información del Centro Sanitario</div>
                                        <div className='col'></div>
                                    </div>

                                    <div className='d-flex flex-wrap justify-content-center align-items-center  mt-2'>
                                        <div className='col-4 col-md-2'>
                                            <div className='f-16 pe-5 bold'>Nombre</div>
                                        </div>

                                        <div className='col-8 col-md-4'>
                                            <input 
                                            
                                            defaultValue={state.newCenter.name} 
                                            className={`nu-input--light p-2`} 
                                            onChange={(evt)=>{
                                                state.newCenter.name = evt.currentTarget.value;
                                                
                                            }}></input>
                                        </div>

                                        <div className='col-4 col-md-2'>
                                            <div className='f-16 ps-md-3 bold'>Tipo de Centro</div>
                                        </div>

                                        <div className='col-8 col-md-4 mt-2 mt-md-0'>
                                            <select 
                                        
                                            
                                            // value={state.centers[i].idCenterType}
                                            className={`nu-input--light p-2`} 
                                            onChange={(evt)=>{
                                                state.newCenter.idCenterType = parseInt(evt.currentTarget.value)
                                                // setState({...state})
                                            }}>

                                            <option value=''>Seleccionar</option> 
                                                {alphaOrder(centerType, 'description').map((item,i)=>{
                                                if(item.id==1) return;
                                        
                                                
                                                return (
                                                    <option key={i} value={item.id}>{item.description}</option> 
                                                )
                                        
                                        })}
                                            </select>


                                        </div>
                                    </div>
    
                                    {/* <div className='d-flex justify-content-center align-items-center  mt-2'>
                                        <div className='col-4 col-md-2'>
                                            <div className='f-16 pe-5 bold'>Nombre</div>
                                        </div>
                                        <div className='col-8 col-md-10'>
                                            <input
                                            className={'nu-input--light p-2'} 
                                            onChange={(evt)=>{
                                                state.newCenter.name = evt.currentTarget.value;
                                            }}></input>
                                        </div>
                                    </div> */}

                                    <div className='text-danger f-12'>{state.errors?.name}</div>
    
                                    <div className='d-flex justify-content-center align-items-center  mt-2'>
                                        <div className='col-4 col-md-2'>
                                            <div className='f-16 pe-5 bold'>Dirección</div>
                                        </div>
                                        <div className='col-8 col-md-10'>
                                            <input 
                                            className={'nu-input--light p-2'} 
                                            onChange={(evt)=>{
                                                state.newCenter.address = evt.currentTarget.value;
                                            }}></input>
                                        </div>
                                    </div>
                                    <div className='text-danger f-12'>{state.errors?.address}</div>
    
                
                                    <div className='d-flex flex-wrap justify-content-center align-items-center  mt-2'>

                                        <div className='col-4 col-md-2'>
                                            <div className='f-16 pe-5 bold'>Código postal</div>
                                        </div>

                                        <div className='col-8 col-md-4'>
                                        <input
                                            className={'nu-input--light p-2'} 
                                            onChange={(evt)=>{
                                                state.newCenter.postalCode = evt.currentTarget.value;
                                            }}></input>
                                            <div className='text-danger f-12'>{state.errors?.postalCode}</div>
                                        </div>


                                        <div className='col-4 col-md-2 mt-2'>
                                            <div className='f-16 ps-md-3 bold'>Provincia</div>
                                        </div>

                                        <div className='col-8 col-md-4  mt-2 mt-md-0'>
                                        <select
                                            onChange={(evt)=>{
                                                state.newCenter.province = evt.currentTarget.value;
                                            }} 
                                            className={'nu-input--light p-2'}>
                                            <option value="" disabled>Seleccionar</option>
                                            {
                                                provincias.map((item, i)=>{
                                                    return(
                                                        <option key={i} value={item.provincia}>{item.provincia}</option>
                                                    )
                                                })
                                            }
                                
                                        </select>
                                        <div className='text-danger f-12'>{state.errors?.province}</div>
                                        </div>
                                    </div>
    
                                    <div className='d-flex justify-content-center align-items-center  mt-2'>
                                        <div className='col-4 col-md-2'>
                                            <div className='f-16 pe-5 bold'>Parking</div>
                                        </div>
                                        <div className='col-8 col-md-10'>
                                        <input 
                                            className={'nu-input--light p-2'} 
                                            onChange={(evt)=>{
                                                state.newDescription.parking = evt.currentTarget.value
                                            }}></input>
                                        </div>
                                    </div>
                                    <div className='text-danger f-12'>{state.errors?.parking}</div>
    
                                    <div className='d-flex justify-content-center align-items-center  mt-2'>
                                        <div className='col-4 col-md-2'>
                                            <div className='f-16 pe-5 bold'>Dietas</div>
                                        </div>
                                        <div className='col-8 col-md-10'>
                                        <input
                                            className={'nu-input--light p-2'} 
                                            onChange={(evt)=>{
                                                state.newDescription.dietas = evt.currentTarget.value
                                            }}></input>
                                        </div>
                                    </div>
                                    <div className='text-danger f-12'>{state.errors?.dietas}</div>
    
                                    <div className='d-flex justify-content-center align-items-center  mt-2'>
                                        <div className='col-4 col-md-2'>
                                            <div className='f-16 pe-5 bold'>Uniforme</div>
                                        </div>
                                        <div className='col-8 col-md-10'>
                                        <input
                                            className={'nu-input--light p-2'} 
                                            onChange={(evt)=>{
                                                state.newDescription.uniforme = evt.currentTarget.value
                                            }}></input>
                                        </div>
                                    </div>
                                    <div className='text-danger f-12'>{state.errors?.uniforme}</div>
    
                                    <div className='d-flex justify-content-center align-items-center  mt-2'>
                                        <div className='col-4 col-md-2'>
                                            <div className='f-16 pe-5 bold'>Punto de encuentro</div>
                                        </div>
                                        <div className='col-8 col-md-10'>
                                        <input
                                            className={'nu-input--light p-2'} 
                                            onChange={(evt)=>{
                                                state.newDescription.location = evt.currentTarget.value
                                            }}></input>
                                        </div>
                                    </div>
                                    <div className='text-danger f-12'>{state.errors?.location}</div>
    
                                    <div className='d-flex justify-content-center align-items-center  mt-2'>
                                        <div className='col-4 col-md-2'>
                                            <div className='f-16 pe-5 bold'>Notas</div>
                                        </div>
                                        <div className='col-8 col-md-10'>
                                        <input 
                                            className={'nu-input--light p-2'} 
                                            onChange={(evt)=>{
                                                state.newDescription.notes = evt.currentTarget.value
                                            }}></input>
                                        </div>
                                    </div>
                                </div>

                                <div className='d-flex mt-3'>
                                    <div className='col'></div>
                                    <div className='nu_btn nu_btn--primary' onClick={()=>{
                                        onCreateCenter()
                                    }}>
                                        <div className='nu-icon nu-icon--mid me-1'>
                                            <IoMdSave size={18}></IoMdSave>
                                        </div>
                                        <div className='nu_btn-text'>Crear</div>
                                    </div>
                                </div>
                            </Renderif>

                            <Renderif isTrue={!addNewCenter}>
                                <div className='d-flex mt-3'>
                                    <div className='col'></div>
                                    <div className='nu_btn nu_btn--overlay--svg nu_btn--overlay' onClick={()=>{
                                        setAddNewCenter(true)
                                    }}>
                                        <div className='nu_btn-text f-18'>Dar de alta un nuevo Centro</div>
                                        <div className='pl-3 mx-2'>
                                            <RiAddCircleLine size={18}></RiAddCircleLine>
                                        </div>
                                        <div className='nu_btn-overlay'></div>
                                    </div>
                                </div>
                            </Renderif>
                            <div className='my-5 py-5'></div>
                        

                        

                        </div>
                    </div>
                </div>



            </div>
            </Renderif>

            
 

    
        </div>

   
   
  
     
    </div>
    </>
  )
}
